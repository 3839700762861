import Vue from 'vue'

const actions = {
  index: ({ _context }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.get('dashboards')
        .then(resp => { resolve(resp) })
        .catch(error => { reject(error) })
    })
  },
  get: ({ _context, dispatch }, { productType, productKey }) => {
    return new Promise((resolve, reject) => {
      // TODO: Enhance pluralization in some way, or betray Roy Fielding through singular endpoints in backend.
      // One solution may be to add an additional field in serializer that return the right endpoint.
      Vue.prototype.$enerforApi.get(`${productType}s/${productKey}`)
        .then(resp => {
          if (resp.data.issues) {
            resp.data.issues.forEach(issue => {
              dispatch('issues/displayIssue', { issue: issue, position: 'is-top' }, { root: true })
            })
          }
          resolve(resp)
        })
        .catch(error => { reject(error) })
    })
  }
}

const modules = {
  irfs: {
    namespaced: true,
    actions: {
      getPerformance: ({ _context }, { productKey, from, to }) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.get(`irfs/performance/${productKey}?from=${from}&to=${to}`)
            .then(resp => { resolve(resp) })
            .catch(error => { reject(error) })
        })
      },
      getTable: ({ _context }, { productKey, from, to }) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.get(`irfs/table/${productKey}?from=${from}&to=${to}`)
            .then(resp => { resolve(resp) })
            .catch(error => { reject(error) })
        })
      }
    }
  },
  tables: {
    namespaced: true,
    actions: {
      getTable: ({ _context }, { productKey, from, to }) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.get(`tables/${productKey}?from=${from}&to=${to}`)
            .then(resp => {
              resp.data.data = resp.data.data.table
              resolve(resp)
            })
            .catch(error => { reject(error) })
        })
      }
    }
  },
  transparencyDashboards: {
    namespaced: true,
    actions: {
      index: ({ _context }, productKey) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.get(`dashboards/${productKey}/transparency-dashboards`)
            .then(resp => { resolve(resp) })
            .catch(error => { reject(error) })
        })
      }
    }
  },
  visualizations: {
    namespaced: true,
    actions: {
      data: ({ _context }, { productKey, from, to }) => {
        return new Promise((resolve, reject) => {
          const fromArg = from ? `from=${from}` : ''
          const toArg = to ? `to=${to}` : ''
          const paramsBegin = (from || to) ? '?' : ''
          const paramsSeparator = (from && to) ? '&' : ''
          Vue.prototype.$enerforApi.get(`visualizations/${productKey}${paramsBegin}${fromArg}${paramsSeparator}${toArg}`)
            .then(resp => {
              resolve(resp)
            })
            .catch(error => { reject(error) })
        })
      }
    }
  },
  releaseNotes: {
    namespaced: true,
    actions: {
      get: ({ _context }) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.get('releasenotes')
            .then(resp => {
              resolve(resp)
            })
            .catch(error => { reject(error) })
        })
      }
    }
  }
}

export default {
  namespaced: true,
  actions,
  modules
}
