<template>
  <section>
    <svg class="pattern left" :class="{in: isDisplayed}" v-if="isDisplayed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1270.23 238.52">
      <path class="item-1" d="M1177.52,9A11.9,11.9,0,0,0,1172,1.64,12,12,0,0,0,1166,0H104.28A12,12,0,0,0,94,18.05L215,227.61a22.07,22.07,0,0,0,19,10.91h802.27a22.07,22.07,0,0,0,19-10.91l121-209.56A12,12,0,0,0,1177.52,9ZM1051.8,225.6a18.08,18.08,0,0,1-15.55,8.92H234a18.09,18.09,0,0,1-15.55-8.92L125.22,64.07H1145Z" style="fill: #8BBC3F" />
      <path class="item-2" d="M1268.71,64.45a3,3,0,0,0-1.49-.39H3A3,3,0,0,0,.39,68.54l12,20.72,10.8,18.83a7,7,0,0,0,6.07,3.55H1240.94a7,7,0,0,0,6.08-3.55l10.79-18.83,12-20.72A3,3,0,0,0,1268.71,64.45Z" style="fill: #009880" />
      <path class="item-3" d="M1268.71,64.45a3,3,0,0,0-1.49-.39H3A3,3,0,0,0,.39,68.54l12,20.72H1257.81l12-20.72A3,3,0,0,0,1268.71,64.45Z" style="fill: #3C50A3" />
      <path class="item-4" d="M472.42,89.38l33.73,58.22a7,7,0,0,0,6,3.49H758.06a7,7,0,0,0,6-3.49l33.73-58.22Z" style="fill: #8BBC3F" />
      <polygon class="item-5" points="420.64 0 457.76 64.07 812.48 64.07 849.6 0 420.64 0" style="fill: #4CB148" />
    </svg>
    <svg class="pattern right" :class="{in: isDisplayed}" v-if="isDisplayed" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1270.23 238.52">
      <path class="item-1" d="M1177.52,9A11.9,11.9,0,0,0,1172,1.64,12,12,0,0,0,1166,0H104.28A12,12,0,0,0,94,18.05L215,227.61a22.07,22.07,0,0,0,19,10.91h802.27a22.07,22.07,0,0,0,19-10.91l121-209.56A12,12,0,0,0,1177.52,9ZM1051.8,225.6a18.08,18.08,0,0,1-15.55,8.92H234a18.09,18.09,0,0,1-15.55-8.92L125.22,64.07H1145Z" style="fill: #f4a74f" />
      <path class="item-2" d="M1268.71,64.45a3,3,0,0,0-1.49-.39H3A3,3,0,0,0,.39,68.54l12,20.72,10.8,18.83a7,7,0,0,0,6.07,3.55H1240.94a7,7,0,0,0,6.08-3.55l10.79-18.83,12-20.72A3,3,0,0,0,1268.71,64.45Z" style="fill: #a83a8e" />
      <path class="item-3" d="M1268.71,64.45a3,3,0,0,0-1.49-.39H3A3,3,0,0,0,.39,68.54l12,20.72H1257.81l12-20.72A3,3,0,0,0,1268.71,64.45Z" style="fill: #d73488" />
      <path class="item-4" d="M472.42,89.38l33.73,58.22a7,7,0,0,0,6,3.49H758.06a7,7,0,0,0,6-3.49l33.73-58.22Z" style="fill: #f4a74f" />
      <polygon class="item-5" points="420.64 0 457.76 64.07 812.48 64.07 849.6 0 420.64 0" style="fill: #e86026" />
    </svg>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({
      isDisplayed: 'patterns/visibility'
    })
  }
}
</script>
