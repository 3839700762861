export const usageAnalyticsMixin = {
  methods: {
    registerAnalytic (product, view, action, optionalValues = null) {
      const payload = {
        product: product,
        view: view,
        action: action,
        values: optionalValues
      }
      this.$store.dispatch('usageAnalytics/sendEvent', { type: 'user_action', payload: payload })
    }
  }
}
