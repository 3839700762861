import Vue from 'vue'
import router from '@/router'
import i18n from '@/i18n'

const state = {
  accessToken: null,
  refreshToken: null,
  isAuthenticated: false,
  user: null
}

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  user: state => state.user,
  accessToken: state => state.accessToken,
  refreshToken: state => state.refreshToken
}

const actions = {
  authenticate: ({ _state, commit, dispatch }, { credentials, landingPath }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.post('token', credentials)
        .then(response => {
          commit('setRefreshToken', response.data.refresh)
          commit('setAccessToken', response.data.access)
          dispatch('fetchUser')
            .then(() => {
              router.push(typeof (landingPath) !== 'undefined' ? landingPath : '/dashboards').catch(() => ({}))
              resolve()
            })
            .catch(error => { reject(error) })
        })
        .catch(error => { reject(error) })
    })
  },
  fetchUser: ({ commit }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.get('current-user')
        .then(response => {
          commit('setLoggedInUser', response.data)
          resolve()
        })
        .catch(error => { reject(error) })
    })
  },
  logout: ({ commit }) => {
    console.debug('logout')
    commit('logout')
    commit('usageAnalytics/disconnect', null, { root: true })
    router.push({ name: 'login' })
      .then(() => {
        commit('clearUserData')
      })
      .catch(() => ({}))
  },
  refreshToken: ({ state, commit }) => {
    return new Promise((resolve, reject) => {
      if (state.refreshToken) {
        Vue.prototype.$enerforApi.post('token/refresh', { refresh: state.refreshToken })
          .then(response => {
            commit('setAccessToken', response.data.access)
            resolve()
          })
          .catch(error => { reject(error) })
      } else {
        reject(Error('no refresh token available'))
      }
    })
  }
}

const mutations = {
  setRefreshToken: (state, refreshToken) => {
    state.refreshToken = refreshToken
  },
  setAccessToken: (state, accessToken) => {
    state.accessToken = accessToken
  },
  // sets state with user information and toggles
  // isAuthenticated from false to true
  setLoggedInUser: (state, user) => {
    i18n.locale = user.locale
    localStorage.setItem('locale', user.locale)
    localStorage.setItem('timezone', user.timezone)
    state.user = user
    state.isAuthenticated = true
  },
  // delete all auth and user information from the state
  clearUserData: state => {
    state.refreshToken = null
    state.accessToken = null
    state.user = null
    state.isAuthenticated = false
  },
  logout: state => {
    state.isAuthenticated = false
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
