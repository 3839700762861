<template>
  <div class="page-header">
    <h4 class="headings-h3">{{ title }}</h4>
    <small v-if="subtitle" class="text-body-small">{{ subtitle }}</small>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    }
  }
}
</script>
