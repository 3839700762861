<template>
  <Plotly :layout="plotlyLayout" :data="plotlyData" />
</template>

<script>
// Components
import Plotly from '@/components/Plotly'
// Mixins
import { fieldsMetadata } from '@/mixins/fieldsMetadata'
import { viewMixin } from '@/mixins/viewMixin'

export default {
  mixins: [fieldsMetadata, viewMixin],
  components: {
    Plotly
  },
  props: {
    plotType: {
      type: String
    }
  },
  computed: {
    plotTypeApplied () {
      if (this.plotType) {
        return this.plotType
      } else {
        return 'scatter'
      }
    },
    plotlyLayout () {
      return {
        title: {
          text: this.name,
          font: {
            family: 'Oxygen Mono',
            size: 16
          },
          xref: 'paper',
          x: 0
        },
        xaxis: {
          showline: true
        },
        yaxis: {
          showline: true,
          hoverformat: '.2f',
          title: {
            text: this.$t(`${this.translationPath}.y-title`),
            font: {
              family: 'Oxygen Mono',
              size: 12
            }
          }
        },
        hovermode: 'x unified',
        legend: {
          x: 0,
          y: -0.25,
          orientation: 'h'
        }
      }
    },
    plotlyData () {
      if (this.data && this.data.length && this.data.length > 0) {
        return this.orderAndFilterKeysByMetadata(Object.keys(this.data[0]))
          .reverse()
          .filter(k => (k !== 'datetime'))
          .map(key => {
            const curve = { x: [], y: [] }
            this.data.forEach(dataPoint => {
              if (dataPoint[key] !== null) {
                curve.x.push(new Date(dataPoint.datetime))
                curve.y.push(dataPoint[key])
              }
            })
            return {
              type: this.plotTypeApplied,
              mode: this.getFieldTraceMode(key),
              fill: this.getFieldFillMode(key),
              name: this.getFieldName(key),
              x: curve.x,
              y: curve.y,
              line: {
                color: this.getFieldColor(key),
                dash: this.getFieldDashMode(key)
              }
            }
          })
      } else {
        return []
      }
    }
  }
}
</script>
