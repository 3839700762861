import Vue from 'vue'

const actions = {
  get: ({ _context }, { modelKey, modelId, from, to }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.get(`transparency-dashboards/${modelKey}?model=${modelId}&from=${from}&to=${to}`)
        .then(resp => { resolve(resp) })
        .catch(error => { reject(error) })
    })
  }
}

export default {
  namespaced: true,
  actions
}
