<template>
  <div class="modal-card data-export-modal">
    <header class="modal-card-head">
      <div class="modal-card-title headings-h3">
        <p>{{ title }}</p>
      </div>
      <button @click="$emit('close')" class="delete" aria-label="close"></button>
    </header>
    <section class="modal-card-body">
      <p v-if="subtitle">{{ subtitle }}</p>
      <FromTo v-model="fromToParameters" @input="registerAnalytic(productKey, 'export_modal', 'export', fromToParameters)"/>
    </section>
    <footer class="modal-card-foot">
      <b-button @click="exportData('json')" type="is-primary" icon-left="file-export">{{ $t('buttons.jsonExport') }}</b-button>
      <b-button @click="exportData('xls')" type="is-primary" icon-left="file-export">{{ $t('buttons.xlsExport') }}</b-button>
      <button @click="$emit('close')" class="button">{{ $t('buttons.cancel') }}</button>
    </footer>
  </div>
</template>

<script>
import moment from 'moment'
import { dateMixin } from '@/mixins/dateMixin'
import { mapGetters } from 'vuex'
import XLSX from 'xlsx'
import { notificationMixin } from '@/mixins/notificationMixin'
import FromTo from '@/components/FromTo'
import { fieldsMetadata } from '@/mixins/fieldsMetadata'
import { usageAnalyticsMixin } from '@/mixins/usageAnalyticsMixin'
export default {
  mixins: [dateMixin, notificationMixin, fieldsMetadata, usageAnalyticsMixin],
  props: {
    productKey: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Change me please'
    },
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    subtitle: {
      type: String
    },
    modelValue: {
      type: Boolean
    }
  },
  data () {
    return {
      baseTranslationPath: 'modals.dataExport',
      fromToParameters: {
        from: null,
        to: null
      }
    }
  },
  components: {
    FromTo
  },
  computed: {
    ...mapGetters({
      isLoading: 'loader/isLoading'
    })
  },
  mounted () {
    this.fromToParameters = {
      from: moment(new Date(this.from)).format('YYYY-MM-DD'),
      to: moment(new Date(this.to)).format('YYYY-MM-DD')
    }
  },
  methods: {
    close () {
      this.$emit('update:modelValue', false)
    },
    exportData (format) {
      const payload = {
        productKey: this.productKey,
        ...this.fromToParameters
      }
      this.$store.dispatch('loader/enableLoading')
      this.$store.dispatch(this.action, payload)
        .then(response => {
          // Convert the timestamps into readable datetimes
          if (response.data.data.length > 0) {
            const datetimeKeys = Object.keys(response.data.data[0]).filter(k => {
              return this.getFieldType(k) === this.typeDatetime
            })
            const timeKeys = Object.keys(response.data.data[0]).filter(k => {
              return this.getFieldType(k) === this.typeTimeTz
            })

            const data = response.data.data.map(entry => {
              const newEntry = entry
              datetimeKeys.forEach(k => {
                const dt = moment(newEntry[k])
                if (dt.isValid()) {
                  newEntry[k] = this.formatDatetime(newEntry[k])
                }
              })
              timeKeys.forEach(k => {
                const dt = moment(newEntry[k])
                if (dt.isValid()) {
                  newEntry[k] = moment(newEntry[k]).format('HH:mm:ssZ')
                }
              })
              return newEntry
            })
            if (format === 'xls') {
              this.exportToXLSX(data)
            } else {
              this.exportToJSON(data)
            }
          }
        })
        .catch((_) => {
          this.toastError(this.$t(`${this.baseTranslationPath}.exportError`))
        })
        .finally(() => {
          this.$store.dispatch('loader/disableLoading')
          this.toastSuccess(this.$t(`${this.baseTranslationPath}.exportSuccessful`))
        })
    },
    exportToXLSX (data) {
      const fileName = `${this.productKey}-${moment(Date.now()).format('YYYY-MMM-DD-HH:mm:ss')}.xlsx`
      const sheet = XLSX.utils.json_to_sheet(data)
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, sheet, 'Data')
      XLSX.writeFile(wb, fileName)
    },
    exportToJSON (data) {
      const a = document.createElement('a')
      const file = new Blob([JSON.stringify(data)], { type: 'application/json' })
      a.href = URL.createObjectURL(file)
      a.download = `${this.productKey}-${moment(Date.now()).format('YYYY-MMM-DD-HH:mm:ss')}.json`
      a.click()
      a.remove()
    }
  }
}
</script>
