<style>

  :root {
    /* Primary N-SIDE colors */

    --nside-black: #535F6B;

    --nside-grey: #7E8790;
    --nside-grey1: #BFC3C7;
    --nside-grey2: #EAEBEC;
    --nside-grey3: #F7F7F7;

    --nside-blue: #00ACC2;
    --nside-blue1: #10C1D1;
    --nside-blue2: #9FE0E8;
    --nside-blue3: #DFF5F7;

    --nside-orange: #F4A74F;
    --nside-orange1: #F7BD7B;
    --nside-orange2: #FBDEBD;
    --nside-orange3: #FEF4E9;

    --nside-green: #8CBB13;
    --nside-green1: #B7D46C;
    --nside-green2: #D4E5A7;
    --nside-green3: #F1F6E2;
  }

  #main-inputsensitivity {

      position: static;
      width: 1100px;
      height: 100%;
      margin: 0 auto;
      padding: 40px;
      padding-top: 10px;
      box-shadow: 20px 0px 20px -20px var(--nside-grey1), -20px 0px 20px -20px var(--nside-grey1);
      background: white;
      border-radius: 10px;

    #header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: 'Oxygen', sans-serif;
      font-size: 1.5rem;
      color: var(--nside-black);
      margin-bottom: 0.2rem;
    }

    #plotMain {
      width: 100%;
      height: 250px;
      margin: 0px;
    }

    .slidecontainer {
      display: flex;
      flex-direction: row;
      width: 100%; /* Width of the outside container */
      justify-content: space-around;
      align-items: flex-start;
      align-content: center;
    }

    .slidershell {
      width: 40px;
      height: 180px;
      margin: 10px;
    }

    .slider {
      /*
      IMPORTANT : Because we want vertical sliders, and also customizable style, we have to rotate horizontal sliders.
      When rotating in such a way, width and height are not swapped, so to make things easier we'll have width = height.
      */
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */

      width: 180px; /* Specific width is required for Firefox. */
      height: 180px; /* Specified height */
      transform: rotate(-90deg);

      background: transparent; /* Otherwise white in Chrome */
      outline: none; /* Remove outline */

      display: inline-block;
      padding: 0px;
      margin: 0px;
      position: relative;
      left: -70px; /* (slider width - slidershell width) /2 */
    }

    .slider:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    /* Special styling for WebKit/Blink */
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 14px;
      width: 14px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
      margin-top: -2px; /* - (height of thumb - height of track)/2 */
      box-shadow: -1px 1px 3px #0d0d0d, 0px 0px 0px 0px #000000; /* Shadow + Border */
    }

    .slider::-webkit-slider-runnable-track {
      height: 10px;
      cursor: pointer;
      box-shadow: -1px 1px 3px #0d0d0d, 0px 0px 0px 0px #000000; /* Shadow + Border */
      border-radius: 3px;
      /*border: 0px solid #010101; /* This is annoying wrt positioning of the thumb, use shadow instead */
    }

    /* Value Box colors */
    .scenarioColor {
      background: var(--nside-blue2);
    }

    .v {
      display: flex;
      flex-direction: column;
    }
    .c {
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    /* Styling box and values */
    .bt {
      font-family: 'Oxygen', sans-serif;
      color: var(--nside-black);
      border: 0px;
      padding: 10px 5px;
      margin: 5px;
      width: 170px;
      text-align: center;
    }

    .bsh {
      border: 0px;
      border-radius: 3px;
      box-shadow: 1px 1px 3px #0d0d0d, 0px 0px 0px 0px #000000; /* Shadow + Border */
    }

    .bigtext {
      font-family: 'Courier New', monospace;
      font-size: 20px;
      font-weight: bold;
    }

    .padright {
      padding-right: 10px;
    }

    .input-number{
      -webkit-appearance: none;
      border: 0px;
      text-align: center;
      padding: 0;
    }
    .input-number::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    #thr-val-span {
      font-weight: bold;
      color: var(--nside-blue)
    }

    #tabs {
      display: flex;
      flex-direction: row;
      align-content: stretch;
      justify-content: space-evenly;
      margin: 10px;
      margin-top: 0px;
    }

    .featcontainer {
      display: flex;
      flex-direction: row;
    }

    .featplot {
      width: 100%;
      height: 200px;
      margin: 0px;
      padding: 0px;
    }

    .tablinkwrapper {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin: 10px;

      background: 'black';

    }

    .tablinks {
      height: 40px;
      width: 100%;
      font-family: 'Oxygen', sans-serif;
      font-size: 16px;
    }

    .tablink-highlighted {
      text-decoration: underline;
    }

    .tablinks:hover {
      opacity: 1;
    }

    #tab1 { background: var(--nside-blue2) }
    #tab2 { background: var(--nside-orange2) }
    #tab3 { background: var(--nside-green2) }

    .scntext {
      background: none;
      border: none;
      display: inline-block
    }

    .scn-check-container {
      cursor: pointer;
      caret-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 5px;
    }

    /* Hide the browser's default checkbox */
    .scn-check-container input {
      display: none;
    }

    /* Hide the checked checkbox */
    .img-checked {
      display: none;
    }

    /* If checked, show the checked image */
    .scn-check-container input:checked ~ .img-checked {
      display: inline;
    }

    /* If checked, hide the unchecked image */
    .scn-check-container input:checked ~ .img-unchecked {
      display: none;
    }

    .visibility-icon {
      height: 25px;
      width: 25px;
    }

  }

</style>

<template>

<div id="main-inputsensitivity">

    <div id="header">
        <span id="title">N-SIDE - Forecast Input Sensitivity - DE Day-Ahead Prices</span>
    </div>

    <div id="plotMain"></div>

    <div id="tabs">
        <div class="tablinkwrapper">
            <button id="tab1" class="bsh tablinks">
                <span>Scenario 1 (</span>
                <span id="scntext1" class="scntext" contenteditable>...</span>
                <span>)</span>
            </button>
            <label class="scn-check-container">
              <input id="vis1" type="checkbox">
              <Icon class="img-unchecked visibility-icon"  icon="carbon:accessibility-color" />
              <Icon class="img-checked visibility-icon"    icon="carbon:accessibility-color-filled" />
            </label>
        </div>

        <div class="tablinkwrapper">
            <button id="tab2" class="bsh tablinks">
                <span>Scenario 2 (</span>
                <span id="scntext2" class="scntext" contenteditable>...</span>
                <span>)</span>
            </button>
            <label class="scn-check-container">
              <input id="vis2" type="checkbox">
              <Icon class="img-unchecked visibility-icon"  icon="carbon:accessibility-color" />
              <Icon class="img-checked visibility-icon"    icon="carbon:accessibility-color-filled" />
            </label>
        </div>

        <div class="tablinkwrapper">
            <button id="tab3" class="bsh tablinks">
                <span>Scenario 3 (</span>
                <span id="scntext3" class="scntext" contenteditable>...</span>
                <span>)</span>
            </button>
            <label class="scn-check-container">
              <input id="vis3" type="checkbox">
              <Icon class="img-unchecked visibility-icon"  icon="carbon:accessibility-color" />
              <Icon class="img-checked visibility-icon"    icon="carbon:accessibility-color-filled" />
            </label>
        </div>

    </div>

    <div id="tabContent">

        <div id="feat1" class="featcontainer">

            <div class="v c">
                <span class="bt">DE Load Forecast</span>
                <span id="vbox1" class="bigtext bsh bt scenarioColor">
                    <input type="text" value="0" size="6" id="ibox1" class="input-number bigtext scenarioColor">
                    <span class="padright">MWh</span>
                </span>
            </div>
            <div class="slidershell">
                <input id="feat1Slider" type="range" min="-30000" max="30000" step="500" value="0" class="slider">
            </div>
            <div id="feat1Plot" class="featplot"></div>
        </div>

        <div id="feat2" class="featcontainer">
            <div class="v c">
                <span class="bt">DE Wind Generation Forecast</span>
                <span id="vbox2" class="bigtext bsh bt scenarioColor">
                    <input type="text" value="0" size="6" id="ibox2" class="input-number bigtext scenarioColor">
                    <span class="padright">MWh</span>
                </span>
            </div>
            <div class="slidershell">
                <input id="feat2Slider" type="range" min="-20000" max="20000" step="500" value="0" class="slider">
            </div>
            <div id="feat2Plot" class="featplot"></div>
        </div>

        <div id="feat3" class="featcontainer">
            <div class="v c">
                <span class="bt">DE Solar Generation Forecast</span>
                <span id="vbox3" class="bigtext bsh bt scenarioColor">
                    <input type="text" value="0" size="6" id="ibox3" class="input-number bigtext scenarioColor">
                    <span class="padright">MWh</span>
                </span>
            </div>
            <div class="slidershell">
                <input id="feat3Slider" type="range" min="-15000" max="15000" step="1000" value="0" class="slider">
            </div>
            <div id="feat3Plot" class="featplot"></div>
        </div>

    </div>

</div>

</template>

<script>
import Plotly from 'plotly.js-dist-min'
import { Icon } from '@iconify/vue2'

export default {

  components: {
    Icon
  },
  mounted () {
    // Get dataJson from backend
    this.$store.dispatch('demos/get', 'inputsensitivity').then(resp => {
      const dataJson = JSON.parse(resp.data)

      /**
         * Gets the value of a CSS variable, useful to access colors
         * i.e : const black = getCssVar('--nside-black')
         */
      function getCssVar (varName) {
        return getComputedStyle(document.documentElement).getPropertyValue(varName)
      }

      // Params
      const nScenarios = 3
      const feats = [
        'DE Load Forecast',
        'DE Wind Generation Forecast',
        'DE Solar Generation Forecast'
      ]
      const nFeats = feats.length

      const scnColors = [
        getCssVar('--nside-blue'),
        getCssVar('--nside-orange'),
        getCssVar('--nside-green')
      ]
      const scnColorsSec = [
        getCssVar('--nside-blue2'),
        getCssVar('--nside-orange2'),
        getCssVar('--nside-green2')
      ]

      // Elements
      const tab1 = document.getElementById('tab1')
      const tab2 = document.getElementById('tab2')
      const tab3 = document.getElementById('tab3')
      const tabs = [tab1, tab2, tab3]

      const vis1 = document.getElementById('vis1')
      const vis2 = document.getElementById('vis2')
      const vis3 = document.getElementById('vis3')
      const vises = [vis1, vis2, vis3]

      const f1sl = document.getElementById('feat1Slider')
      const f2sl = document.getElementById('feat2Slider')
      const f3sl = document.getElementById('feat3Slider')
      const sliders = [f1sl, f2sl, f3sl]

      const ibox1 = document.getElementById('ibox1')
      const ibox2 = document.getElementById('ibox2')
      const ibox3 = document.getElementById('ibox3')
      const iboxes = [ibox1, ibox2, ibox3]

      const scntext1 = document.getElementById('scntext1')
      const scntext2 = document.getElementById('scntext2')
      const scntext3 = document.getElementById('scntext3')
      const scntexts = [scntext1, scntext2, scntext3]

      // State
      const scenariosOffsets = {}
      for (let scn = 0; scn < nScenarios; scn++) {
        scenariosOffsets[scn] = {}
        feats.forEach(feat => {
          scenariosOffsets[scn][feat] = 0
        })
      }

      let selectedScenario = 0
      vis1.checked = true

      // Elements Interactions

      function setOffset (scn, featidx, offset) {
        const offsetn = Number(offset)
        let prefix = ''
        if (offsetn > 0) { prefix = '+' }
        scenariosOffsets[scn][feats[featidx]] = offsetn
        sliders[featidx].value = offsetn
        iboxes[featidx].value = `${prefix}${offsetn}`
        redrawPlotFeat(featidx)
        redrawPlot()
      }
      f1sl.oninput = function () { setOffset(selectedScenario, 0, this.value) }
      f2sl.oninput = function () { setOffset(selectedScenario, 1, this.value) }
      f3sl.oninput = function () { setOffset(selectedScenario, 2, this.value) }
      ibox1.oninput = function () { setOffset(selectedScenario, 0, this.value) }
      ibox2.oninput = function () { setOffset(selectedScenario, 1, this.value) }
      ibox3.oninput = function () { setOffset(selectedScenario, 2, this.value) }

      // Refresh plot when the text of a tab button changes
      scntext1.oninput = function () { selectScenario(selectedScenario) }
      scntext2.oninput = function () { selectScenario(selectedScenario) }
      scntext3.oninput = function () { selectScenario(selectedScenario) }

      function selectScenario (_event, scn) {
        selectedScenario = scn
        vises[scn].checked = true

        for (let slidx = 0; slidx < nFeats; slidx++) {
          setOffset(selectedScenario, slidx, scenariosOffsets[selectedScenario][feats[slidx]])
        }

        // Change colors on non-plotly stuff
        const coloredElems = document.getElementsByClassName('scenarioColor')
        for (const elem of coloredElems) {
          elem.style.background = scnColorsSec[scn]
        }

        highlightScenarioButton(scn)

        redrawPlotFeat(0)
        redrawPlotFeat(1)
        redrawPlotFeat(2)
        redrawPlot()
      }

      function highlightScenarioButton (scn) {
        tabs.forEach(tab => {
          tab.classList.remove('tablink-highlighted')
        })
        tabs[scn].classList.add('tablink-highlighted')
      }
      highlightScenarioButton(selectedScenario)

      tab1.onclick = function () { selectScenario(null, 0) }
      tab2.onclick = function () { selectScenario(null, 1) }
      tab3.onclick = function () { selectScenario(null, 2) }

      vis1.onclick = function () { selectScenario(null, selectedScenario) }
      vis2.onclick = function () { selectScenario(null, selectedScenario) }
      vis3.onclick = function () { selectScenario(null, selectedScenario) }

      // Multipole contributions
      function computeDipoleContrib (ts, offset, splits, tensor) {
        return ts.map(y => y + offset).map(y => {
          for (let spl = 0; spl < splits.length; spl++) {
            if (y < splits[spl]) {
              return tensor[spl]
            }
          }
          return tensor[splits.length - 1]
        })
      }

      function computeMultipoleContrib2d (ts1, ts2, offset1, offset2, splits1, splits2, tensor) {
        const spls1 = ts1.map(y => y + offset1).map(y => {
          for (let spl = 0; spl < splits1.length; spl++) {
            if (y < splits1[spl]) {
              return spl
            }
          }
          return splits1.length - 1
        })

        const spls2 = ts2.map(y => y + offset2).map(y => {
          for (let spl = 0; spl < splits2.length; spl++) {
            if (y < splits2[spl]) {
              return spl
            }
          }
          return splits2.length - 1
        })

        return spls1.map((_, i) => tensor[spls1[i]][spls2[i]])
      }

      function computeDipoleContribsForOffsets (offsetDict) {
        const totContribs = new Array(dataJson.ts_index.length).fill(0)

        Object.keys(dataJson.multipoles).forEach(mpKey => {
          const featidxs = dataJson.multipoles[mpKey].features
          const splits = dataJson.multipoles[mpKey].splits
          const tensor = dataJson.multipoles[mpKey].tensor

          if (featidxs.length === 1) {
            const featidx = featidxs[0]
            const feat = dataJson.features[featidx].name
            const ts = dataJson.features[featidx].ts
            const offset = offsetDict[feat] ?? 0
            computeDipoleContrib(ts, offset, splits[0], tensor).map((e, i) => { totContribs[i] += e })
          } else if (featidxs.length === 2) {
            const featidx1 = featidxs[0]
            const featidx2 = featidxs[1]
            const feat1 = dataJson.features[featidx1].name
            const feat2 = dataJson.features[featidx2].name
            const ts1 = dataJson.features[featidx1].ts
            const ts2 = dataJson.features[featidx2].ts
            const splits1 = splits[0]
            const splits2 = splits[1]
            const offset1 = offsetDict[feat1] ?? 0
            const offset2 = offsetDict[feat2] ?? 0
            computeMultipoleContrib2d(ts1, ts2, offset1, offset2, splits1, splits2, tensor).map((e, i) => { totContribs[i] += e })
          }
        })
        return totContribs.map((y, i) => (y + dataJson.monopole_term) * dataJson.ttf_gas_break_even[i])
      }

      // Traces
      const actualColor = 'black'

      const traceActual = {
        x: dataJson.ts_index,
        y: dataJson.ts_actual,
        name: 'Actual',
        mode: 'scatter',
        marker: { color: actualColor },
        showlegend: true
      }

      function traceForecastReconstr (scn, color) {
        const txt = getScenarioText(scn)
        let additionalTxt = ''
        if (txt !== '...') {
          additionalTxt = `<br>(${txt})`
        }
        return {
          x: dataJson.ts_index,
          y: computeDipoleContribsForOffsets(scenariosOffsets[scn]),
          name: `Forecast with<br>input Scenario ${scn + 1}${additionalTxt}`,
          mode: 'scatter',
          marker: { color: color },
          showlegend: true
        }
      }

      const traceForecastOrig = {
        x: dataJson.ts_index,
        y: computeDipoleContribsForOffsets(Object.fromEntries(feats.map(feat => [feat, 0]))),
        name: 'Original Forecast',
        mode: 'scatter',
        marker: { color: 'lightgrey' },
        showlegend: true
      }

      function getScenarioText (scn) {
        const content = scntexts[scn].textContent
        return content || ''
      }

      function traceFeatActual (featidx) {
        const feat = dataJson.features[featidx].name
        return {
          x: dataJson.ts_index,
          y: dataJson.features[featidx].ts,
          name: feat,
          mode: 'scatter',
          marker: { color: 'lightgrey' },
          showlegend: false
        }
      }
      function traceFeatOffset (featidx) {
        const feat = dataJson.features[featidx].name
        const offset = scenariosOffsets[selectedScenario][feat]
        return {
          x: dataJson.ts_index,
          y: (dataJson.features[featidx].ts).map(y => Math.max(y + offset, 0)),
          name: feat,
          mode: 'scatter',
          marker: { color: scnColors[selectedScenario] },
          showlegend: false
        }
      }

      const layout = {
        margin: { t: 0, b: 40 },
        yaxis: {
          title: 'DE DAP (€/MWh)'
        },
        xaxis: {
        },
        legend: {
          x: -0.3,
          y: 0.9
        }
      }

      const layoutFeatBase = {
        margin: { t: 0 },
        yaxis: {
        },
        xaxis: {
        }
      }

      function getTraces () {
        const traces = [traceForecastOrig, traceActual]
        if (vis1.checked) {
          traces.push(traceForecastReconstr(0, scnColors[0]))
        }
        if (vis2.checked) {
          traces.push(traceForecastReconstr(1, scnColors[1]))
        }
        if (vis3.checked) {
          traces.push(traceForecastReconstr(2, scnColors[2]))
        }
        return traces
      }

      const plot = document.getElementById('plotMain')
      Plotly.newPlot(
        plot,
        getTraces(),
        layout
      )
      function redrawPlot () {
        Plotly.react(
          plot,
          getTraces(),
          layout
        )
      }

      const plotFeat1 = document.getElementById('feat1Plot')
      const plotFeat2 = document.getElementById('feat2Plot')
      const plotFeat3 = document.getElementById('feat3Plot')
      const plotsFeat = [plotFeat1, plotFeat2, plotFeat3]

      for (let plotidx = 0; plotidx < plotsFeat.length; plotidx++) {
        const plotFeat = plotsFeat[plotidx]
        Plotly.newPlot(
          plotFeat,
          [
            traceFeatActual(dataJson.featidx_lookup[feats[plotidx]]),
            traceFeatOffset(dataJson.featidx_lookup[feats[plotidx]])
          ],
          layoutFeatBase
        )
      }

      function redrawPlotFeat (plotidx) {
        const plotFeat = plotsFeat[plotidx]
        Plotly.react(
          plotFeat,
          [
            traceFeatActual(dataJson.featidx_lookup[feats[plotidx]]),
            traceFeatOffset(dataJson.featidx_lookup[feats[plotidx]])
          ],
          layoutFeatBase
        )
      }
    })
  }
}

</script>
