<template>
<div id="login-view">
  <section>
    <div class="login-box box">

      <div class="logo">
        <img :src="require('@/assets/images/logos/nefp.svg')" :alt="$t('general.enerforLogoAlt')">
      </div>

      <div class="header">
        Login or request an account to start using the N-SIDE Energy Forecasting Platform service.
      </div>

      <hr>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit, valid }">
        <div class="form" @keyup.enter="handleSubmit(login)">
          <ValidationProvider slim rules="required" name="username" v-slot="{ errors, valid }">
            <b-field :label="$t('inputs.username.label')" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
              <p class="control has-icons-left">
                <b-input v-model="credentials.username" name="username" :placeholder="$t('inputs.username.placeholder')"/>
                <span class="icon is-small is-left"><Icon icon="fluent:inprivate-account-16-regular" /></span>
              </p>
            </b-field>
          </ValidationProvider>

          <ValidationProvider slim rules="required" name="password" v-slot="{ errors, valid }">
            <b-field :label="$t('inputs.password.label')" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
              <p class="control has-icons-left">
                <b-input v-model="credentials.password" name="password" type="password" :placeholder="$t('inputs.password.placeholder')"/>
                <span class="icon is-small is-left"><Icon icon="ic:baseline-password" /></span>
              </p>
            </b-field>
          </ValidationProvider>

          <b-button name="submit" @click="handleSubmit(login)" :disabled="!valid" expanded type="is-primary">{{ $t('buttons.login') }}</b-button>
        </div>
      </ValidationObserver>
    </div>
  </section>

  <footer class="footer">
    <div class="content has-text-centered">
      <p class="text-label">
        Copyright © 2021-2022 N-SIDE. All rights reserved.
      </p>
    </div>
  </footer>
</div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Icon } from '@iconify/vue2'
import { notificationMixin } from '@/mixins/notificationMixin'

export default {
  mixins: [notificationMixin],
  components: {
    Icon,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      credentials: {
        username: null,
        password: null
      },
      targetRoute: null
    }
  },
  beforeRouteEnter (to, _from, next) {
    next(vm => {
      vm.targetRoute = to.query.next
    })
  },
  methods: {
    login () {
      this.$store.dispatch('sessions/authenticate', { credentials: this.credentials, landingPath: this.targetRoute })
        .then(_ => this.$store.dispatch('usageAnalytics/connect'))
        .then(_ => { this.toastSuccess(this.$t('toasts.successLogin')) })
        .catch(_ => { this.toastError(this.$t('toasts.failedLogin')) })
    }
  }
}
</script>
