import Vue from 'vue'

const actions = {
  updateCurrentUser: ({ commit, dispatch, rootState }, payload) => {
    return new Promise((resolve, reject) => {
      dispatch('update', { userId: rootState.sessions.user.id, payload: payload })
        .then(response => {
          commit('sessions/setLoggedInUser', response.data, { root: true })
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  },
  update: (_context, { userId, payload }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.patch(`users/${userId}`, payload)
        .then(response => {
          resolve(response)
        })
        .catch(error => { reject(error) })
    })
  }
}

const modules = {
  passwords: {
    namespaced: true,
    actions: {
      update: ({ _context, rootState }, payload) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.post(`users/${rootState.sessions.user.id}/passwords`, payload)
            .then(resp => {
              resolve(resp)
            })
            .catch(error => {
              reject(error)
            })
        })
      }
    }
  },
  apiKey: {
    namespaced: true,
    actions: {
      get: ({ _context }, userId) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.get(`users/${userId}/api-key`)
            .then(resp => { resolve(resp) })
            .catch(error => { reject(error) })
        })
      },
      update: ({ _context }, userId) => {
        return new Promise((resolve, reject) => {
          Vue.prototype.$enerforApi.post(`users/${userId}/api-key`)
            .then(resp => { resolve(resp) })
            .catch(error => { reject(error) })
        })
      }
    }
  }
}

export default {
  namespaced: true,
  actions,
  modules
}
