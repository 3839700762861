export const browsingAnalyticMixin = {
  data () {
    return {
      // This is used to uniquely identify the tracking entry
      trackingHash: null
    }
  },
  computed: {
    analyticsInfos () {
      throw new SyntaxError('analyticsInfo() not implemented by the mixing component.')
      /* example implementation
        return {
          product: product,
          view: view,
          optional_values: optionalValues (can be omitted)
        }
      */
    }
  },
  methods: {
    updateBrowsingAnalytics () {
      this.stopBrowsingTracking()
      this.trackingHash = crypto.randomUUID()
      this.startBrowsingTracking()
    },
    startBrowsingTracking () {
      this.$store.dispatch(
        'usageAnalytics/sendEvent',
        { type: 'browsing', id: this.trackingHash, payload: { ...this.analyticsInfos, action: 'browsing' } }
      )
        .then(_ => ({}))
    },
    stopBrowsingTracking () {
      this.$store.dispatch(
        'usageAnalytics/sendEvent',
        { type: 'stop_browsing', id: this.trackingHash }
      )
        .then(_ => ({}))
    }
  },
  mounted () {
    this.trackingHash = crypto.randomUUID()
    this.startBrowsingTracking()
  },
  beforeDestroy () {
    this.stopBrowsingTracking()
  }
}
