<template>
  <!-- A Plotly wrapper for Plotly.js -->
  <div className="plotlyContainer" :id="plotlyContainerId"/>
</template>

<script>
import Plotly from 'plotly.js-dist-min'

export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    layout: {
      type: Object,
      default: () => {
        return {}
      }
    },
    config: {
      type: Object,
      default: () => {
        return {
          responsive: true
        }
      }
    }
  },
  methods: {
    triggerWindowResize () {
      window.dispatchEvent(new Event('resize'))
    }
  },
  watch: {
    // Plotly is not reactive like Vue, so we must help it a bit
    data: {
      handler () {
        Plotly.react(this.$el, this.data, this.layout)
        this.triggerWindowResize()
      },
      deep: true
    }
  },
  data () {
    return {
      plotlyContainerId: `plotly-container-${this._uid}`
    }
  },
  mounted () {
    Plotly.newPlot(this.plotlyContainerId, this.data, this.layout, this.config)
    this.triggerWindowResize()
  }
}
</script>
