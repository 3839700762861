<template>
  <section id="api-view" class="is-spaced">
    <PageHeader :title="$t(`${baseTranslationPath}.title`)" :subtitle="$t(`${baseTranslationPath}.subtitle`)"/>
    <a class="button is-primary" :href="downloadLink" download="n-side-energy-forecasting-specification.json">{{$t(`${baseTranslationPath}.downloadSpec`)}}</a>
    <div class="box" id="swagger-ui"/>
    <div class="box" id="mobile-only">{{ $t(`${baseTranslationPath}.mobileOnly`) }}</div>
  </section>
</template>

<script>
import PageHeader from '@/components/layout/headers/PageHeader'
import SwaggerUI from 'swagger-ui'
import 'swagger-ui/dist/swagger-ui.css'
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      baseTranslationPath: 'views.api',
      spec: null,
      apiKey: null
    }
  },
  components: {
    PageHeader
  },
  computed: {
    ...mapGetters({
      user: 'sessions/user'
    }),
    downloadLink () {
      return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.spec))}`
    }
  },
  created () {
    this.$store.dispatch('users/apiKey/get', this.user.id)
      .then(resp => { this.apiKey = resp.data })
      .catch(error => { console.log(error) })
  },
  mounted () {
    this.$store.dispatch('api/getSpecification')
      .then(resp => {
        const serv = [
          {
            url: process.env.VUE_APP_ENERFOR_BACKEND_HOST
          }
        ]
        this.spec = { ...resp, servers: serv }
        const ui = SwaggerUI({
          spec: this.spec,
          dom_id: '#swagger-ui',
          defaultModelsExpandDepth: -1,
          onComplete: () => {
            ui.preauthorizeApiKey('tokenAuth', `Token ${this.apiKey}`)
          }
        })
      })
  }
}
</script>
