<template>
  <section>
    <FromTo v-model="fromToParameters" @input="registerAnalytic(name, 'aggregation_plot', 'from_to_change', fromToParameters)"/>
    <Plotly :layout="plotlyLayout" :data="plotlyData" />
  </section>
</template>

<script>
// Components
import Plotly from '@/components/Plotly'
// Mixins
import { fieldsMetadata } from '@/mixins/fieldsMetadata'
import { dateMixin } from '@/mixins/dateMixin'
import { viewMixin } from '@/mixins/viewMixin'
import FromTo from '@/components/FromTo'
import { usageAnalyticsMixin } from '@/mixins/usageAnalyticsMixin'

export default {
  mixins: [fieldsMetadata, viewMixin, dateMixin, usageAnalyticsMixin],
  components: {
    FromTo,
    Plotly
  },
  props: {
    plotType: {
      type: String
    }
  },
  data () {
    return {
      fromToParameters: {
        from: null,
        to: null
      }
    }
  },
  computed: {
    plotTypeApplied () {
      if (this.plotType) {
        return this.plotType
      } else {
        return 'bar'
      }
    },
    plotlyLayout () {
      return {
        title: {
          text: this.name,
          font: {
            family: 'Oxygen Mono',
            size: 16
          },
          xref: 'paper',
          x: 0
        },
        xaxis: {
          showline: true
        },
        yaxis: {
          showline: true,
          hoverformat: '.2f',
          title: {
            text: this.$t(`${this.translationPath}.y-title`),
            font: {
              family: 'Oxygen Mono',
              size: 12
            }
          }
        },
        legend: {
          x: 0,
          y: -0.25,
          orientation: 'h'
        },
        barmode: 'group'
      }
    },
    plotlyData () {
      if (this.data && this.data.length && this.data.length > 0) {
        let keys = Object.keys(this.data[0])
          .filter(k => (k !== 'datetime') && this.getFieldAggregation(k))

        // If metadata was provided from the backend, we only keep fields defined in them
        if (this.metadata && Object.keys(this.metadata).length > 0) {
          keys = keys.filter(k => Object.keys(this.metadata).includes(k))
        }

        const groups = [...new Set(keys.map(k => {
          return this.getFieldAggregation(k).group
        }))]

        const traces = [...new Set(keys.map(k => {
          return this.getFieldAggregation(k).trace
        }))]

        let data = this.data
        if (this.fromToParameters.from) {
          data = data.filter(d => this.dateStringToValue(d.datetime) >= this.dateStringToValue(this.fromToParameters.from))
        }
        if (this.fromToParameters.to) {
          data = data.filter(d => this.dateStringToValue(d.datetime) <= this.dateStringToValue(this.fromToParameters.to))
        }

        const aggregatedData = {}
        keys.forEach(k => {
          aggregatedData[k] = data.reduce((acc, item) => acc + (item[k] || 0), 0)
        })

        return traces.map(t => {
          const y = groups.map(g => {
            const key = keys.find(k => this.getFieldAggregation(k).trace === t && this.getFieldAggregation(k).group === g)
            return aggregatedData[key]
          })
          return {
            type: this.plotTypeApplied,
            name: this.getFieldName(t),
            x: groups.map(g => this.getFieldName(g)),
            y: y,
            bar: { color: this.getFieldColor(t) },
            hovertemplate: '%{y}<extra></extra>', // "extra" item is used to disable trace hover info
            hoverinfo: 'skip'
          }
        })
      } else {
        return []
      }
    }
  },
  beforeMount () {
    const datetimes = this.data.map(elem => elem.datetime)
    this.fromToParameters.from = datetimes.reduce((a, b) => Math.min(a, b))
    this.fromToParameters.to = datetimes.reduce((a, b) => Math.max(a, b))
  }
}
</script>
