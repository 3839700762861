import Vue from 'vue'

const actions = {
  index: ({ _context }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.get('demos')
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  },
  get: ({ _context }, key) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.get(`demos/${key}`)
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  actions
}
