import Vue from 'vue'

const actions = {
  getSpecification: ({ _context }) => {
    return new Promise((resolve, reject) => {
      Vue.prototype.$enerforApi.get('schema')
        .then(response => resolve(response.data))
        .catch(error => reject(error))
    })
  }
}

export default {
  namespaced: true,
  actions
}
