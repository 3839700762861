<template>
  <span class="tag text-label-mono" :class="tagData.class">{{ tagData.text }}</span>
</template>

<script>
export default {
  props: ['status'],
  computed: {
    tagData () {
      if (this.status === 0) {
        return {
          class: 'is-dark',
          text: this.$t('status.normal')
        }
      } else if (this.status === 1) {
        return {
          class: 'is-warning',
          text: this.$t('status.nuclear_variation')
        }
      } else {
        return {
          class: 'is-warning',
          text: this.$t('status.error')
        }
      }
    }
  }
}
</script>
