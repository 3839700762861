import axios from 'axios'
import store from '@/store'
import router from '@/router'
import i18n from '@/i18n'
import { notificationMixin } from '@/mixins/notificationMixin'

export default () => {
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_ENERFOR_BACKEND_HOST}/api`,
    withCredentials: false,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })

  instance.interceptors.request.use(
    // On fulfilled request
    config => {
      const token = store.getters['sessions/accessToken']
      if (token) { config.headers.Authorization = `Bearer ${token}` }
      return config
    },
    // On error with request
    error => Promise.reject(error)
  )

  instance.interceptors.response.use(
    // If the response is successful (HTTP 2xx/3xx), proceed
    response => response,
    error => {
      const originalRequest = error.config
      // The refresh token has failed, we persist the user as logged out and redirect to login
      if (error.response.status === 401 && originalRequest.url.includes('token/refresh')) {
        store.commit('sessions/clearUserData')
        router.push({ name: 'login', params: { 0: router.currentRoute.path } }).catch(() => ({}))
        return Promise.reject(error)
      } else if (isTokenAuthenticationError(error) && !originalRequest._retry) {
        // The refresh token has failed, we persist the user as logged out and redirect to login
        originalRequest._retry = true
        return store.dispatch('sessions/refreshToken')
          .then(() => {
            return new Promise(resolve => {
              instance(originalRequest).then(response => {
                resolve(response)
              })
            })
          })
          .catch(refreshError => {
            store.dispatch('sessions/logout').catch(() => ({}))
            return Promise.reject(refreshError)
          })
      } else {
        // Handle error to display notification in app
        handleStandardError(error)
        // Reject promise to let further handling of the issue in calling function
        return Promise.reject(error)
      }
    }
  )

  return instance
}

const isTokenAuthenticationError = (error) => {
  return error.response.data.detail !== 'no_active_account' && error.response.status === 401
}

const handleStandardError = (error) => {
  // Server fault is the default message
  let message = `${i18n.tc('globalMessages.serverError')} <a class="toast-mail" href="mailto:${process.env.VUE_APP_FLEXPLAN_MAIL}">${process.env.VUE_APP_FLEXPLAN_MAIL}</a>`
  switch (error.response.status) {
    case 400:
      message = i18n.tc('globalMessages.badRequest')
      break
    case 401:
      if (error.response.data.detail === 'no_active_account') {
        message = i18n.tc('globalMessages.noActiveAccount')
      } else {
        message = i18n.tc('globalMessages.unauthorized')
      }
      break
    case 403:
      message = i18n.tc('globalMessages.forbidden')
      break
    case 404:
      message = i18n.tc('globalMessages.notFound')
      break
    default:
      break
  }
  notificationMixin.methods.toastError(message, 5000)
}
