<template>
  <span class="tag text-label-mono" :class="tagData.class">{{ tagData.text }}</span>
</template>

<script>
export default {
  props: ['reco'],
  computed: {
    tagData () {
      if (this.reco === 2) {
        return {
          class: 'is-success',
          text: 'LARGE LONG'
        }
      } else if (this.reco === 1) {
        return {
          class: 'is-success',
          text: 'LONG'
        }
      } else if (this.reco === 0) {
        return {
          class: 'is-dark',
          text: 'HOLD'
        }
      } else if (this.reco === -1) {
        return {
          class: 'is-danger',
          text: 'SHORT'
        }
      } else if (this.reco === -2) {
        return {
          class: 'is-danger',
          text: 'LARGE SHORT'
        }
      } else {
        return {
          class: 'is-warning',
          text: 'ERROR'
        }
      }
    }
  }
}
</script>
