<template>
  <div class="modal-card" style="width: auto">
    <header class="modal-card-head">
      <p class="modal-card-title headings-h3">{{ $t(`${baseTranslationPath}.modalTitle`) }}</p>
      <button type="button" class="delete" @click="$emit('close')"/>
    </header>
    <ValidationObserver slim ref="observer" v-slot="{ handleSubmit, valid }">
      <section class="modal-card-body">
        <ValidationProvider slim rules="required" name="username" v-slot="{ errors, valid }">
          <b-field :label="$t(`${baseTranslationPath}.oldPassword`)" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
            <p class="control has-icons-left">
              <b-input type="password" v-model="oldPassword" name="oldPassword" placeholder="***********"/>
              <span class="icon is-small is-left"><Icon icon="ic:baseline-password" /></span>
            </p>
          </b-field>
        </ValidationProvider>

        <ValidationProvider vid="newPassword" slim rules="required|password" name="newPassword" v-slot="{ errors, valid }">
          <b-field :label="$t(`${baseTranslationPath}.newPassword`)" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
            <p class="control has-icons-left">
              <b-input type="password" v-model="newPassword" name="newPassword" placeholder="***********"/>
              <span class="icon is-small is-left"><Icon icon="ic:baseline-password" /></span>
            </p>
          </b-field>
        </ValidationProvider>

        <ValidationProvider slim rules="required|password|confirmed:newPassword" name="newPasswordConfirmation" v-slot="{ errors, valid }">
          <b-field :label="$t(`${baseTranslationPath}.newPasswordConfirmation`)" :type="{ 'is-danger': errors[0], 'is-success': valid }" :message="errors">
            <p class="control has-icons-left">
              <b-input type="password" v-model="newPasswordConfirmation" name="newPasswordConfirmation" placeholder="***********"/>
              <span class="icon is-small is-left"><Icon icon="ic:baseline-password" /></span>
            </p>
          </b-field>
        </ValidationProvider>
      </section>
      <footer class="modal-card-foot">
        <b-button label="Cancel" @click="$emit('close')" />
        <b-button :disabled="!valid" label="Update my password" type="is-primary" @click="handleSubmit(updatePassword)"/>
      </footer>
    </ValidationObserver>

  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Icon } from '@iconify/vue2'

export default {
  props: ['active'],
  components: {
    ValidationObserver,
    ValidationProvider,
    Icon
  },
  data () {
    return {
      baseTranslationPath: 'views.settings.sections.account.updatePasswordModal',
      oldPassword: null,
      newPassword: null,
      newPasswordConfirmation: null
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    updatePassword () {
      const payload = {
        old_password: this.oldPassword,
        new_password: this.newPassword,
        new_password_confirmation: this.newPasswordConfirmation
      }
      this.$store.dispatch('users/passwords/update', payload)
        .then(_ => {
          this.$emit('close')
          this.$buefy.toast.open({
            type: 'is-success',
            message: this.$t(`${this.baseTranslationPath}.passwordChanged`)
          })
        })
        .catch(_ => {
          this.$buefy.toast.open({
            type: 'is-danger',
            message: _.response.data.non_field_errors
          })
        })
    }
  }
}
</script>
