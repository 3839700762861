<template>
  <section id="application-settings">
    <SectionHeader :title="$t(`${baseTranslationPath}.applicationSettings.title`)" :subtitle="$t(`${baseTranslationPath}.applicationSettings.subtitle`)"/>

    <div class="section-content">
      <b-field :label="$t(`${baseTranslationPath}.applicationSettings.language`)">
        <b-select :value="user.locale" @input="updateUser($event, 'locale')" placeholder="Select a language" expanded>
          <option v-for="(lang, i) in $i18n.availableLocales" :key="`Lang${i}`" :value="lang">{{ $t(`locales.${lang}` ) }}</option>
        </b-select>
      </b-field>

      <b-field :label="$t(`${baseTranslationPath}.applicationSettings.timezone`)">
        <b-select :value="user.timezone" @input="updateUser($event, 'timezone')" placeholder="Select a timezone" expanded>
          <option v-for="(tz, i) in timezones" :key="`Tz${i}`" :value="tz">{{ tz }}</option>
        </b-select>
      </b-field>
    </div>
  </section>
</template>

<script>
import SectionHeader from '@/components/layout/headers/SectionHeader'
import { mapGetters } from 'vuex'
import moment from 'moment'
import 'moment-timezone'
export default {
  data () {
    return {
      baseTranslationPath: 'views.settings.sections.application'
    }
  },
  components: {
    SectionHeader
  },
  computed: {
    ...mapGetters({
      user: 'sessions/user'
    }),
    timezones () {
      return moment.tz.names()
    }
  },
  methods: {
    updateUser (event, attribute) {
      const payload = { [attribute]: event }
      this.$store.dispatch('users/updateCurrentUser', payload)
        .then(() => {
          this.$buefy.toast.open({
            message: 'Your settings have been updated!',
            type: 'is-success'
          })
        })
    }
  }
}
</script>
