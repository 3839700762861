<template>
  <section id="settings-view" class="settings columns">
    <div class="column is-one-fifth">
      <SideMenu/>
    </div>

    <div class="column is-two-fifths">
      <div class="box settings-content">
        <router-view></router-view>
      </div>
    </div>
  </section>
</template>

<script>
import SideMenu from '@/views/Settings/SideMenu.vue'
import { browsingAnalyticMixin } from '@/mixins/browsingAnalyticMixin'
export default {
  mixins: [browsingAnalyticMixin],
  components: {
    SideMenu
  },
  computed: {
    analyticsInfos () {
      return {
        view: 'settings',
        product: 'none'
      }
    }
  }
}
</script>
