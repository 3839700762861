import sassColors from '@/assets/stylesheets/abstracts/_variables.scss'
export const fieldsMetadata = {
  props: {
    metadata: {
      type: Object,
      required: false
    }
  },
  data () {
    return {
      typeDatetime: 'datetime',
      typeTimeTz: 'timetz',
      typeString: 'string',
      typeNumber: 'number',
      typeReco: 'reco',
      typeStatus: 'status',
      defaultColor: sassColors.interfaceGray60,
      // Order matters here and define some visualization orders (such as table columns for example)
      fieldsMetadata: {
        datetime: {
          type: 'datetime'
        },
        ref_time: {
          type: 'datetime'
        },
        publication_time: {
          type: 'timetz'
        },
        reco: {
          type: 'reco'
        },
        nu_var: {
          type: 'status'
        },
        rms_reco: {
          type: 'number'
        },
        rms_max_deviation: {
          type: 'number'
        },
        rms_reco_after_max_deviation: {
          type: 'number'
        },
        rms_reco_britned: {
          type: 'number'
        },
        ranking_forecast: {
          type: 'number'
        },
        spread_forecast: {
          type: 'number'
        },
        sgain_cum: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        lgain_cum: {
          type: 'number',
          color: sassColors.nsideGreen
        },
        gain_cum: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        small_volume_gain_cum: {
          type: 'number',
          color: sassColors.nsidePink
        },
        large_volume_gain_cum: {
          type: 'number',
          color: sassColors.nsideDarkBlue
        },
        capa_fees_cum: {
          type: 'number',
          color: sassColors.nsidePink
        },
        forecast_imb_price_avg: {
          type: 'number',
          color: sassColors.nsidePink
        },
        forecast_pos_avg: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_avg: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_imb_price_P90: {
          type: 'number',
          color: sassColors.nsideBlue,
          fillMode: 'tonexty',
          lineMode: 'none'
        },
        forecast_imb_price_P10: {
          type: 'number',
          color: sassColors.nsideBlue,
          lineMode: 'none'
        },
        forecast_imb_pos_price_P90: {
          type: 'number',
          color: sassColors.nsideOrange,
          fillMode: 'tonexty',
          lineMode: 'none'
        },
        forecast_imb_pos_price_P10: {
          type: 'number',
          color: sassColors.nsideOrange,
          lineMode: 'none'
        },
        forecast_imb_neg_price_P90: {
          type: 'number',
          color: sassColors.nsideBlue,
          fillMode: 'tonexty',
          lineMode: 'none'
        },
        forecast_imb_neg_price_P10: {
          type: 'number',
          color: sassColors.nsideBlue,
          lineMode: 'none'
        },
        forecast_imb_price_P75: {
          type: 'number',
          color: sassColors.nsideBlue,
          fillMode: 'tonexty',
          lineMode: 'none'
        },
        forecast_imb_price_P25: {
          type: 'number',
          color: sassColors.nsideBlue,
          lineMode: 'none'
        },
        imb_pos: {
          type: 'number'
        },
        imb_neg: {
          type: 'number'
        },
        forecast_pos: {
          type: 'number',
          color: sassColors.nsideDarkBlue
        },
        forecast_pos_average: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qp05: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qm10: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qm25: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qm40: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qm55: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qm85: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_pos_qm115: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_neg: {
          type: 'number',
          color: sassColors.nsideRed
        },
        forecast_neg_average: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qp05: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qm10: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qm25: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qm40: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qm55: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qm85: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_neg_qm115: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_nrv_P90: {
          type: 'number',
          color: sassColors.nsideBlue,
          fillMode: 'tonexty',
          lineMode: 'none'
        },
        forecast_nrv_P10: {
          type: 'number',
          color: sassColors.nsideBlue,
          lineMode: 'none'
        },
        forecast_nrv_P75: {
          type: 'number',
          color: sassColors.nsideBlue,
          fillMode: 'tonexty',
          lineMode: 'none'
        },
        forecast_nrv_P25: {
          type: 'number',
          color: sassColors.nsideBlue,
          lineMode: 'none'
        },
        spread_actual: {
          type: 'number'
        },
        ranking_actual: {
          type: 'number'
        },
        actual_nrv: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        da: {
          type: 'number',
          color: sassColors.nsideDarkBlue
        },
        pos: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        neg: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        nl_imb_pos: {
          type: 'number'
        },
        nl_imb_neg: {
          type: 'number'
        },
        pl_imb: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        mipf_forecast_pos_current_quarter: {
          type: 'number'
        },
        mipf_forecast_neg_current_quarter: {
          type: 'number'
        },
        mipf_forecast_pos_next_quarter: {
          type: 'number'
        },
        mipf_forecast_neg_next_quarter: {
          type: 'number'
        },
        expected_rs: {
          type: 'number'
        },
        gb_imb: {
          type: 'number'
        },
        be_imb: {
          type: 'number'
        },
        imb: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        counter_imb: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        scheduled_flow: {
          type: 'number'
        },
        physical_flow: {
          type: 'number'
        },
        price_be_gb: {
          type: 'number'
        },
        price_gb_be: {
          type: 'number'
        },
        status: {
          type: 'string'
        },
        reco_id1: {
          type: 'reco'
        },
        id1: {
          type: 'number'
        },
        reco_id3: {
          type: 'reco'
        },
        id3: {
          type: 'number'
        },
        price_cap: {
          type: 'number'
        },
        forecast_best: {
          type: 'number'
        },
        forecast_nemo_physical_flow: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        forecast_flow: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        actual_flow: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        forecast_nemo_physical_flow_refprog: {
          type: 'number',
          color: sassColors.nsideGreen
        },
        forecast_qp05: {
          type: 'number',
          color: sassColors.nsideGreen,
          dashMode: 'dash'
        },
        forecast_qm10: {
          type: 'number',
          color: sassColors.nsideRed,
          dashMode: 'dash'
        },
        forecast_qm25: {
          type: 'number',
          color: sassColors.nsideBlue,
          dashMode: 'dash'
        },
        forecast_qm40: {
          type: 'number',
          color: sassColors.nsideBrown,
          dashMode: 'dash'
        },
        forecast_qm55: {
          type: 'number',
          color: sassColors.nsidePink,
          dashMode: 'dash'
        },
        r2_down_price: {
          type: 'number'
        },
        r2_up_price: {
          type: 'number'
        },
        qp05_forecast: {
          type: 'number',
          aggregation: {
            group: 'qp05',
            trace: 'forecast'
          }
        },
        qm10_forecast: {
          type: 'number',
          aggregation: {
            group: 'qm10',
            trace: 'forecast'
          }
        },
        qm25_forecast: {
          type: 'number',
          aggregation: {
            group: 'qm25',
            trace: 'forecast'
          }
        },
        qm40_forecast: {
          type: 'number',
          aggregation: {
            group: 'qm40',
            trace: 'forecast'
          }
        },
        qm55_forecast: {
          type: 'number',
          aggregation: {
            group: 'qm55',
            trace: 'forecast'
          }
        },
        qp05_baseline: {
          type: 'number',
          aggregation: {
            group: 'qp05',
            trace: 'baseline'
          }
        },
        qm10_baseline: {
          type: 'number',
          aggregation: {
            group: 'qm10',
            trace: 'baseline'
          }
        },
        qm25_baseline: {
          type: 'number',
          aggregation: {
            group: 'qm25',
            trace: 'baseline'
          }
        },
        qm40_baseline: {
          type: 'number',
          aggregation: {
            group: 'qm40',
            trace: 'baseline'
          }
        },
        qm55_baseline: {
          type: 'number',
          aggregation: {
            group: 'qm55',
            trace: 'baseline'
          }
        },
        qp05_max_gain: {
          type: 'number',
          aggregation: {
            group: 'qp05',
            trace: 'max_gain'
          }
        },
        rmse_forecast: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'forecast'
          }
        },
        rmse_forecast_pos: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'forecast_pos'
          }
        },
        rmse_forecast_neg: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'forecast_neg'
          }
        },
        rmse_refprog: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'baseline'
          }
        },
        rmae_forecast: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'forecast'
          }
        },
        rmae_forecast_pos: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'forecast_pos'
          }
        },
        rmae_forecast_neg: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'forecast_neg'
          }
        },
        rmae_refprog: {
          type: 'number',
          aggregation: {
            group: 'month',
            trace: 'baseline'
          }
        },
        forecast: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        baseline: {
          color: sassColors.nsideGreen
        },
        max_gain: {
          color: sassColors.nsideBlue
        },
        optimality: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        savings_per_reco: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        nl_mipf_latency: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        tennet_balance_delta_latency: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        sav_baseline: {
          type: 'number',
          color: sassColors.nsideOrange
        },
        sav_britned: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        sav_delta: {
          type: 'number',
          color: sassColors.nsideGreen
        },
        rmse_bench_imb: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        rmse_bench_last_3_imb: {
          type: 'number',
          color: sassColors.nsideGreen
        },
        rmae_bench_imb: {
          type: 'number',
          color: sassColors.nsideBlue
        },
        rmae_bench_last_3_imb: {
          type: 'number',
          color: sassColors.nsideGreen
        }
      }
    }
  },
  methods: {
    orderAndFilterKeysByMetadata (keys) {
      if (
        this.metadata &&
        Object.keys(this.metadata).length > 0
      ) {
        return Object.keys(this.metadata).filter(k => keys.indexOf(k) > -1).sort(
          (md1, md2) => this.metadata[md1].ordering_position - this.metadata[md2].ordering_position
        )
      } else {
        return Object.keys(this.fieldsMetadata).filter(k => keys.indexOf(k) > -1)
      }
    },
    getFieldName (key) {
      const locale = this.$i18n.locale
      // First we check for our info in the externalMetadata provided by the Backend
      if (
        this.metadata &&
        key in this.metadata &&
        this.metadata[key].locales &&
        locale in this.metadata[key].locales
      ) {
        return this.metadata[key].locales[locale]
      } else {
        // Otherwise we use the old solution as fallback, using the Frontend defined translations
        const translationKey = `views.dashboards.fields.${key}`
        if (this.$te(translationKey)) {
          return this.$t(translationKey)
        } else {
          console.warn(`Untranslated field with key: ${key}`)
          return key
        }
      }
    },
    _getFieldAttribute (key, attribute, defaultValue, externalConverter = null) {
      // First we check for our info in the externalMetadata provided by the Backend
      if (
        this.metadata &&
        key in this.metadata &&
        this.metadata[key].attributes &&
        attribute in this.metadata[key].attributes
      ) {
        const v = this.metadata[key].attributes[attribute]
        if (v) {
          // If defined : apply conversion to the data (i.e string -> color)
          if (externalConverter !== null) {
            return externalConverter(v)
          } else {
            return v
          }
        } else {
          return defaultValue
        }
      } else if (key in this.fieldsMetadata) {
        // Otherwise we use the old solution as fallback, using the Frontend defined fieldsMetadata
        const v = this.fieldsMetadata[key][attribute]
        if (v) {
          return v
        } else {
          return defaultValue
        }
      } else {
        // Key not found in Back/Frontend metadata
        console.warn(`Undefined field key: ${key}`)
        return null
      }
    },
    _externalColorConversion (colorString) {
      // attempts sass colorString -> color hex (i.e 'nsideBlue' -> #3384D0)
      if (colorString in sassColors) {
        return sassColors[colorString]
      }
      return colorString
    },
    _emptyAggregationConversion (aggregationDict) {
      // if both aggregation group and trace are null, then return null
      if (
        aggregationDict &&
        'group' in aggregationDict &&
        'trace' in aggregationDict &&
        !aggregationDict.group &&
        !aggregationDict.trace
      ) {
        return null
      } else {
        return aggregationDict
      }
    },
    getFieldType (key) {
      return this._getFieldAttribute(key, 'type', this.typeString)
    },
    getFieldColor (key) {
      return this._getFieldAttribute(key, 'color', this.defaultColor, this._externalColorConversion)
    },
    getFieldAggregation (key) {
      return this._getFieldAttribute(key, 'aggregation', null, this._emptyAggregationConversion)
    },
    getFieldTraceMode (key) {
      return this._getFieldAttribute(key, 'traceMode', 'line')
    },
    getFieldFillMode (key) {
      return this._getFieldAttribute(key, 'fillMode', null)
    },
    getFieldDashMode (key) {
      return this._getFieldAttribute(key, 'dashMode', 'solid')
    }
  }
}
