<template>
  <div class="box sidemenu">
    <b-menu>
      <b-menu-list label="Settings">
        <b-menu-item @click="navigate('applicationSettings')" :active="isActive('applicationSettings')" icon="cog-box" label="Application"></b-menu-item>
        <b-menu-item @click="navigate('accountSettings')" :active="isActive('accountSettings')" icon="account" label="My Account"></b-menu-item>
      </b-menu-list>
      <b-menu-list label="Actions">
        <b-menu-item :active="false" @click="passwordModalActive = true" icon="lock-reset" label="Password Update"></b-menu-item>
        <b-menu-item @click="navigate('logout')" icon="exit-to-app" label="Logout"></b-menu-item>
      </b-menu-list>
    </b-menu>

    <!-- Modals -->
    <b-modal :active.sync="passwordModalActive">
      <UpdatePasswordModal @close="passwordModalActive = false"/>
    </b-modal>
  </div>
</template>

<script>
import UpdatePasswordModal from '@/views/Settings/UpdatePasswordModal'
export default {
  components: {
    UpdatePasswordModal
  },
  data () {
    return {
      passwordModalActive: false
    }
  },
  methods: {
    navigate (routeName) {
      this.$router.push({ name: routeName })
    },
    isActive (routeName) {
      return this.$route.matched.some(({ name }) => name === routeName)
    }
  }
}
</script>
