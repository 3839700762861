<template>
  <b-field class="from-to-component" grouped>
    <b-field :label="$t(`${baseTranslationPath}.fromPeriod`)">
      <b-datepicker
        v-model="from"
        @input="updateDate('from', $event)"
        :placeholder="$t(`${baseTranslationPath}.clickToSelect`)"
        icon="calendar-today"
        trap-focus>
      </b-datepicker>
    </b-field>

    <b-field :label="$t(`${baseTranslationPath}.toPeriod`)">
      <b-datepicker
        v-model="to"
        @input="updateDate('to', $event)"
        :placeholder="$t(`${baseTranslationPath}.clickToSelect`)"
        icon="calendar-today"
        trap-focus>
      </b-datepicker>
    </b-field>
  </b-field>
</template>

<script>

import { dateMixin } from '@/mixins/dateMixin'
import { isEqual } from 'lodash'

export default {
  props: {
    value: {
      type: Object,
      default: () => ({
        from: new Date(),
        to: new Date()
      })
    }
  },
  mixins: [dateMixin],
  data () {
    return {
      baseTranslationPath: 'components.fromTo',
      from: null,
      to: null
    }
  },
  watch: {
    value (newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.updateLocalFromTo(newValue)
      }
    }
  },
  methods: {
    updateLocalFromTo ({ from, to }) {
      this.from = new Date(from)
      this.to = new Date(to)
    },
    updateDate (key, date) {
      const params = { ...this.value }
      params[key] = this.formatDate(date)
      this.$emit('input', params)
    }
  },
  mounted () {
    this.updateLocalFromTo(this.value)
  }
}
</script>
