<template>
  <b-navbar v-if="currentUser" aria-label="main navigation">

    <template #brand>
      <b-navbar-item tag="router-link" :to="{ name: 'dashboards' }">
        <img class="nefp-logo" :src="require('@/assets/images/logos/nefp.svg')" width="160" :alt="$t('general.enerforLogoAlt')">
      </b-navbar-item>
    </template>

    <template #end>
      <router-link :to="{ name: 'dashboards'}" v-slot="{ isActive, navigate }" custom>
        <a @click="navigate" class="navbar-item headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.dashboards') }}</span>
            <span class="icon"><Icon icon="carbon:dashboard" /></span>
          </span>
        </a>
      </router-link>

      <router-link :to="{ name: 'demos'}" v-slot="{ isActive, navigate }" v-if="currentUser.has_demos" custom>
        <a @click="navigate" class="navbar-item headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.demos') }}</span>
            <span class="icon"><Icon icon="carbon:analytics-custom" /></span>
          </span>
        </a>
      </router-link>

      <router-link :to="{ name: 'api'}" v-slot="{ isActive, navigate }" custom>
        <a @click="navigate" class="navbar-item headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.api') }}</span>
            <span class="icon"><Icon icon="bi:journal-code" /></span>
          </span>
        </a>
      </router-link>

      <b-navbar-item :href="currentUser.service_desk_link">
        <a class="headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.serviceDesk') }}</span>
            <span class="icon"><Icon icon="carbon:service-desk" /></span>
          </span>
        </a>
      </b-navbar-item>

      <b-navbar-item :href="adminPanelUrl" v-if="currentUser.is_staff">
        <a class="headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.admin') }}</span>
            <span class="icon"><Icon icon="ic:baseline-admin-panel-settings" /></span>
          </span>
        </a>
      </b-navbar-item>

      <router-link :to="{ name: 'applicationSettings'}" v-slot="{ isActive, navigate }" custom>
        <a @click="navigate" class="navbar-item headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.settings') }}</span>
            <span class="icon"><Icon icon="carbon:settings" /></span>
          </span>
        </a>
      </router-link>

      <router-link :to="{ name: 'logout'}" v-slot="{ isActive, navigate }" custom>
        <a @click="navigate" class="navbar-item headings-h5 center-right-icon" :class="{active: isActive}">
          <span class="icon-text">
            <span>{{ $t('navbar.item.logout') }}</span>
            <span class="icon"><Icon icon="mdi:logout" /></span>
          </span>
        </a>
      </router-link>
    </template>
  </b-navbar>
</template>

<script>
import { mapGetters } from 'vuex'
import { Icon } from '@iconify/vue2'

export default {
  data () {
    return {
      isActive: false
    }
  },
  components: {
    Icon
  },
  computed: {
    ...mapGetters({
      currentUser: 'sessions/user'
    }),
    adminPanelUrl () {
      return `${process.env.VUE_APP_ENERFOR_BACKEND_HOST}/admin`
    }
  }
}
</script>
