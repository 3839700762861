import { mapGetters } from 'vuex'
import moment from 'moment'
import 'moment-timezone'

export const dateMixin = {
  computed: {
    ...mapGetters({
      currentUser: 'sessions/user'
    }),
    timezone () {
      return this.currentUser.timezone || process.env.VUE_APP_FALLBACK_TIMEZONE
    }
  },
  methods: {
    localizeDate (date) {
      return moment(date).tz(this.timezone).locale(this.$i18n.locale)
    },
    formatDate (date) {
      return this.localizeDate(date).format('YYYY-MM-DD')
    },
    formatDatetime (date) {
      return this.localizeDate(date).format('YYYY-MM-DD HH:mm:ssZ')
    },
    dateStringToValue (dateString) {
      return moment.utc(dateString).format('X')
    },
    formatTimeTz (date) {
      const momentDate = moment(date)
      if (momentDate.isValid()) {
        return momentDate.tz(this.timezone).locale(this.$i18n.locale).format('HH:mm:ssZ')
      } else {
        return ''
      }
    }
  }
}
