import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store/index'
import Login from '@/views/Login'
import Dashboards from '@/views/Dashboards'
import Demos from '@/views/Demos'
import API from '@/views/API'
import Settings from '@/views/Settings'
import ApplicationSettings from '../views/Settings/ApplicationSettings'
import AccountSettings from '../views/Settings/AccountSettings'
import Product from '@/views/Dashboards/Product'
import TransparencyDashboard from '@/views/Dashboards/TransparencyDashboard'
import NotFound from '@/views/NotFound'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresUnauth: true }
  },
  {
    path: '/dashboards',
    name: 'dashboards',
    component: Dashboards,
    meta: { requiresAuth: true },
    children: [
      {
        path: ':product/:productKey/home',
        component: Product,
        meta: { requiresAuth: true }
      },
      {
        path: ':product/:productKey/transparency',
        component: TransparencyDashboard,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/demos',
    name: 'demos',
    component: Demos,
    meta: { requiresAuth: true },
    children: [
      {
        path: ':key',
        component: Demos,
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: '/api',
    name: 'api',
    component: API,
    meta: { requiresAuth: true }
  },
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    children: [
      { path: '', redirect: { path: '/settings/application' } },
      { path: 'application', name: 'applicationSettings', component: ApplicationSettings },
      { path: 'account', name: 'accountSettings', component: AccountSettings }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    // This route fools the router by associating only logout logic to a path in the app
    path: '/logout',
    name: 'logout',
    component: {
      beforeRouteEnter () {
        store.dispatch('sessions/logout')
      }
    }
  },
  {
    path: '/',
    redirect: '/dashboards',
    meta: { requiresAuth: true }
  },
  {
    // Special path for 404 to be able to router.push('/404') if needed
    path: '/404',
    name: '404',
    component: NotFound
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // Same route guard (Pragmatic solution to this issue, and the prettiest regarding the current configuration.)
  // Direct navigation from a very specific route to a less specific one (for the same base) is ditched.
  // This prevents issues with dashboards where it makes no sense (for now) to redirect to the base /dashboard
  // As we use the routing system with children, what happens when you click on dashboards when on a specific product view is the following :
  //  - Redirect to the top route (dashboard) which throws away the product component
  //  - The selectors remain the same because already part of the route root component
  //  - The main dashboard page is not remounted because already there and living happily in the DOM
  if (from.path.match(new RegExp(`${to.path}.*`))) {
    next(false)
    return
  }
  // Pattern Guards
  const routeWithPatterns = ['login']
  store.dispatch('patterns/hidePatterns').then(_ => ({}))
  if (routeWithPatterns.includes(to.name)) {
    setTimeout(
      () => store.dispatch('patterns/showPatterns').then(_ => ({})),
      200
    )
  }

  // Authentication guards
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    // Routes that explicitly require authenticated user
    if (!store.getters['sessions/isAuthenticated']) {
      next('/login?next=' + to.path)
    } else {
      next()
    }
  } else if (to.matched.some((record) => record.meta.requiresUnauth)) {
    // Routes that explicitly require anonymous user
    if (store.getters['sessions/isAuthenticated']) {
      next('/dashboards')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
