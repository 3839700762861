<style>

  :root {
    /* Primary N-SIDE colors */

    --nside-black: #535F6B;

    --nside-grey: #7E8790;
    --nside-grey1: #BFC3C7;
    --nside-grey2: #EAEBEC;
    --nside-grey3: #F7F7F7;

    --nside-blue: #00ACC2;
    --nside-blue1: #10C1D1;
    --nside-blue2: #9FE0E8;
    --nside-blue3: #DFF5F7;

    --nside-orange: #F4A74F;
    --nside-orange1: #F7BD7B;
    --nside-orange2: #FBDEBD;
    --nside-orange3: #FEF4E9;

    --nside-green: #8CBB13;
    --nside-green1: #B7D46C;
    --nside-green2: #D4E5A7;
    --nside-green3: #F1F6E2;
  }

  #main-mfrractivation {

    position: static;
    width: 1100px;
    height: 100%;
    margin: 0 auto;
    padding: 40px;
    box-shadow: 20px 0px 20px -20px var(--nside-grey1), -20px 0px 20px -20px var(--nside-grey1);
    background: white;
    border-radius: 10px;

    #header {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 10px;
      font-family: 'Oxygen', sans-serif;
      font-size: 2rem;
      color: var(--nside-black);
      margin-bottom: 1rem;
    }

    #plot {
      width: 100%;
      height: 500px;
      margin: 0px;
    }

    .slidecontainer {
      display: flex;
      flex-direction: row;
      width: 100%; /* Width of the outside container */
      justify-content: space-around;
      align-items: flex-start;
      align-content: center;
    }

    .slider {
      /*
      IMPORTANT : Because we want vertical sliders, and also customizable style, we have to rotate horizontal sliders.
      When rotating in such a way, width and height are not swapped, so to make things easier we'll have width = height.
      */
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */

      width: 200px; /* Specific width is required for Firefox. */
      height: 200px; /* Specified height */
      transform: rotate(-90deg);

      background: transparent; /* Otherwise white in Chrome */
      outline: none; /* Remove outline */
      padding: 0px;
      margin: 5px;
    }

    .slider:focus {
      outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
    }

    /* Special styling for WebKit/Blink */
    .slider::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 50px;
      width: 20px;
      border-radius: 3px;
      background: #ffffff;
      cursor: pointer;
      margin-top: -18px; /* - (height of thumb - height of track)/2 */
      box-shadow: -1px 1px 4px #0d0d0d, 0px 0px 0px 0px #000000; /* Shadow + Border */
    }

    .slider::-webkit-slider-runnable-track {
      height: 14px;
      cursor: pointer;
      box-shadow: -1px 1px 3px #0d0d0d, 0px 0px 0px 0px #000000; /* Shadow + Border */
      border-radius: 3px;
      /*border: 0px solid #010101; /* This is annoying wrt positioning of the thumb, use shadow instead */
    }

    /* Slider colors */
    #sl1::-webkit-slider-runnable-track {
      background: var(--nside-blue);
    }
    #sl2::-webkit-slider-runnable-track {
      background: var(--nside-green);
    }
    #sl3::-webkit-slider-runnable-track {
      background: var(--nside-orange);
    }
    /* Value Box colors */
    #vbox1 { background: var(--nside-blue2); }
    #ibox1 { background: var(--nside-blue2); }
    #vbox2 { background: var(--nside-green2); }
    #ibox2 { background: var(--nside-green2); }
    #vbox3 { background: var(--nside-orange2); }
    #ibox3 { background: var(--nside-orange2); }

    .v {
      display: flex;
      flex-direction: column;
    }
    .c {
      justify-content: space-around;
      align-items: center;
      align-content: center;
    }

    /* Styling box and values */
    .bt {
      font-family: 'Oxygen', sans-serif;
      color: var(--nside-black);
      border: 0px;
      padding: 10px 22px;
      margin: 5px;
      min-width: 50px;
      text-align: center;
    }

    .bsh {
      border: 0px;
      border-radius: 3px;
      box-shadow: 1px 1px 3px #0d0d0d, 0px 0px 0px 0px #000000; /* Shadow + Border */
    }

    .bigtext {
      font-family: 'Courier New', monospace;
      font-size: 20px;
      font-weight: bold;
    }

    .padright {
      padding-right: 10px;
    }

    .input-number{
      -webkit-appearance: none;
      border: 0px;
      text-align: center;
      padding: 0;
    }
    .input-number::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    #thr-val-span {
      font-weight: bold;
      color: var(--nside-blue)
    }
  }

</style>

<template>
  <div id="main-mfrractivation">
      <div id="header">
          <span id="title">N-SIDE - mFRR Activation Tool</span>
      </div>
      <div id="plot"></div>
      <div id="knobs" class="slidecontainer">
          <div class="v c">
              <span id="vbox1" class="bigtext bsh bt">
                  <input type="number" min="-100" max="100" value="10" id="ibox1" class="input-number bigtext">
                  <span class="padright">MW</span>
              </span>
              <input type="range" min="-100" max="100" value="10" step="5" class="slider" id="sl1">
              <span class="bsh bt">IMB Threshold</span>
          </div>
          <div class="v c">
              <span id="vbox2" class="bigtext bsh bt">
                  <input type="number" min="1" max="99" value="25" id="ibox2" class="input-number bigtext">
                  <span class="padright">%</span>
              </span>
              <input type="range" min="1" max="99" value="25" class="slider" id="sl2">
              <span class="bsh bt">Exceedence Risk</span>
              <span class="bt">P(IMB > <span id="thr-val-span">10</span> MW)</span>
          </div>
          <div class="v c">
              <span id="vbox3" class="bigtext bsh bt">
                  <input type="number" min="1" max="99" value="5" id="ibox3" class="input-number bigtext">
                  <span class="padright">%</span>
              </span>
              <input type="range" min="1" max="99" value="5" class="slider" id="sl3">
              <span class="bsh bt">Counter-Activation Risk</span>
              <span class="bt">mFRR Volume</span>
          </div>
      </div>
  </div>
</template>

<script>
import Plotly from 'plotly.js-dist-min'
export default {

  mounted () {
    // Get dataJson from backend
    this.$store.dispatch('demos/get', 'mfrractivation').then(resp => {
      const dataJson = JSON.parse(resp.data)

      /**
         * Gets the value of a CSS variable, useful to access colors
         * i.e : const black = getCssVar('--nside-black')
         */
      function getCssVar (varName) {
        return getComputedStyle(document.documentElement).getPropertyValue(varName)
      }

      const sl1 = document.getElementById('sl1')
      const sl2 = document.getElementById('sl2')
      const sl3 = document.getElementById('sl3')

      const box1 = document.getElementById('ibox1')
      const box2 = document.getElementById('ibox2')
      const box3 = document.getElementById('ibox3')

      const thrValSpan = document.getElementById('thr-val-span')

      box1.setAttribute('value', sl1.value)
      box2.setAttribute('value', sl2.value)
      box3.setAttribute('value', sl3.value)

      // Slider -> Box coupling
      sl1.oninput = function () { box1.value = this.value; redrawPlot(); thrValSpan.innerHTML = sl1.value }
      sl2.oninput = function () { box2.value = this.value; redrawPlot() }
      sl3.oninput = function () { box3.value = this.value; redrawPlot() }

      // Box -> Slider coupling
      box1.oninput = function () { sl1.value = this.value; redrawPlot() }
      box2.oninput = function () { sl2.value = this.value; redrawPlot() }
      box3.oninput = function () { sl3.value = this.value; redrawPlot() }

      // Actuals trace
      const actualColor = 'black'
      const actualColorBg = 'rgba(255,255,255,0.1)'

      const traceActual = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.actual),
        name: 'IMB Actual',
        mode: 'scatter',
        marker: { color: actualColor },
        line: { shape: 'hvh' },
        legendgroup: 'actual',
        showlegend: true
      }
      const traceActualBg = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.actual),
        name: 'IMB Actual',
        mode: 'scatter',
        marker: { color: actualColorBg },
        line: { shape: 'hvh', width: 10 },
        legendgroup: 'actual',
        showlegend: false
      }

      // Forecast traces
      const fcstFillColor = `${getCssVar('--nside-green')}15` // Green + transparency (50/FF)
      // const fcst_line_color = getCssVar('--nside-green')
      const fcstTextColor = getCssVar('--nside-green')

      const traceFcst01 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['1']),
        name: 'Forecast P 1%',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', width: 0 },
        legendgroup: 'fcst_probas',
        showlegend: false
      }
      const traceFcst10 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['10']),
        name: 'Forecast P 10%',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', width: 0 },
        legendgroup: 'fcst_probas',
        showlegend: false
      }
      const traceFcst25 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['25']),
        name: 'Forecast P 25%',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', width: 0 },
        legendgroup: 'fcst_probas',
        showlegend: false
      }
      const traceFcst50 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['50']),
        name: 'Forecast<br>Probability Distribution',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', color: fcstTextColor, width: 1 },
        legendgroup: 'fcst_probas',
        showlegend: true
      }
      const traceFcst75 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['75']),
        name: 'Forecast P 75%',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', width: 0 },
        fill: 'tonexty',
        fillcolor: fcstFillColor,
        legendgroup: 'fcst_probas',
        showlegend: false
      }
      const traceFcst90 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['90']),
        name: 'Forecast P 90%',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', width: 0 },
        fill: 'tonexty',
        fillcolor: fcstFillColor,
        legendgroup: 'fcst_probas',
        showlegend: false
      }
      const traceFcst99 = {
        x: Object.keys(dataJson),
        y: Object.values(dataJson).map(e => e.probas['99']),
        name: 'Forecast P 99%',
        mode: 'scatter',
        marker: { color: fcstTextColor },
        line: { shape: 'hvh', width: 0 },
        fill: 'tonexty',
        fillcolor: fcstFillColor,
        legendgroup: 'fcst_probas',
        showlegend: false
      }

      // Threshold trace
      function traceThreshold () {
        const thrLineColor = getCssVar('--nside-blue')
        const thrTextColor = getCssVar('--nside-blue')

        return {
          x: Object.keys(dataJson),
          y: Array(Object.keys(dataJson).length).fill(sl1.value),
          name: 'IMB Threshold',
          marker: { color: thrTextColor },
          line: { dash: 'dot', color: thrLineColor }
        }
      }

      function traceProbaForThr () {
        const pbthLineColor = getCssVar('--nside-green')
        const pbthTextColor = getCssVar('--nside-green')

        return {
          x: Object.keys(dataJson),
          y: computeThreshold(100 - sl2.value),
          name: `Forecast with :<br>P(IMB > Forecast) = ${sl2.value} %`,
          marker: { color: pbthTextColor },
          line: { shape: 'hvh', color: pbthLineColor, width: 2 }
        }
      }

      function computeProbas (th) {
        // This is the probability of being below th, returned in %
        return Object.values(dataJson).map(e => {
          let pPrev = null
          let vPrev = null

          let pNext = null
          let vNext = null

          const probas = e.probas
          let break_ = false
          Object.keys(probas).forEach(p => {
            if (!break_) {
              if (probas[p] >= th) {
                pNext = p
                vNext = probas[p]
                break_ = true
              } else {
                pPrev = p
                vPrev = probas[p]
              }
            }
          })

          if (vPrev == null) {
            return parseInt(pNext)
          }
          if (vNext == null) {
            return parseInt(pPrev)
          }

          // Interpolation
          const itv = vNext - vPrev
          const distPrev = th - vPrev
          const distNext = vNext - th
          const wPrev = 1 - (distPrev / itv)
          const wNext = 1 - (distNext / itv)

          return parseInt(pPrev) * wPrev + parseInt(pNext) * wNext
        })
      }

      function computeThreshold (proba) {
        // There is p % proba of being under the threshold
        return Object.values(dataJson).map(e => {
          let pPrev = null
          let vPrev = null

          let pNext = null
          let vNext = null

          const probas = e.probas
          let break_ = false
          Object.keys(probas).forEach(pStr => {
            const p = Number(pStr)
            if (!break_) {
              if (p >= proba) {
                pNext = p
                vNext = probas[p]
                break_ = true
              } else {
                pPrev = p
                vPrev = probas[p]
              }
            }
          })

          // Interpolation
          const itv = pNext - pPrev
          const distPrev = proba - pPrev
          const distNext = pNext - proba
          const wPrev = 1 - (distPrev / itv)
          const wNext = 1 - (distNext / itv)

          return vPrev * wPrev + vNext * wNext
        })
      }

      // Activated volumes
      function computeActivationVolumes () {
        const pBelowThr = computeProbas(sl1.value)
        const thBottom = computeThreshold(sl3.value)

        return pBelowThr.map(function (e, i) { return [e, thBottom[i]] })
      }

      function traceActVol () {
        const actFillColor = `${getCssVar('--nside-orange')}80`
        const actLineColor = getCssVar('--nside-orange')
        const actTextColor = getCssVar('--nside-orange')

        return {
          x: Object.keys(dataJson),
          y: computeActivationVolumes().map(actCondVol => {
            const activationCondition = (100 - actCondVol[0]) >= sl2.value
            const activationVolume = Math.max(0, actCondVol[1])

            return activationCondition * activationVolume
          }),
          name: 'Forecast based mFRR',
          marker: { color: actTextColor },
          line: { shape: 'hvh', color: actLineColor },
          fill: 'tozeroy',
          fillcolor: actFillColor
        }
      }

      const rngsliderStart = new Date(Object.keys(dataJson)[Object.keys(dataJson).length - 24 * 4]).getTime()
      const rngsliderEnd = new Date(Object.keys(dataJson)[Object.keys(dataJson).length - 1]).getTime()

      const layout = {
        margin: { t: 0 },
        yaxis: {
          title: 'System Imbalance (MW)',
          range: [-170, 170]
        },
        xaxis: {
          rangeslider: {
            thickness: 0.05
          },
          range: [
            rngsliderStart,
            rngsliderEnd
          ]
        },
        legend: {
          x: 1.02,
          y: 0.9
        }
      }

      const plot = document.getElementById('plot')
      Plotly.newPlot(
        plot,
        [
          traceFcst01,
          traceFcst99,

          traceFcst10,
          traceFcst90,

          traceFcst25,
          traceFcst75,

          traceFcst50,

          traceProbaForThr(),

          traceActualBg,
          traceActual,

          traceThreshold(),

          traceActVol()
        ],
        layout
      )

      function redrawPlot () {
        Plotly.react(
          plot,
          [
            traceFcst01,
            traceFcst99,

            traceFcst10,
            traceFcst90,

            traceFcst25,
            traceFcst75,

            traceFcst50,

            traceProbaForThr(),

            traceActualBg,
            traceActual,

            traceThreshold(),

            traceActVol()
          ],
          layout
        )
      }
    })
  }
}

</script>
