const state = {
  loading: false
}

const getters = {
  isLoading: state => state.loading
}

const actions = {
  enableLoading: ({ commit }) => {
    commit('setLoadingState', true)
  },
  disableLoading: ({ commit }) => {
    commit('setLoadingState', false)
  }
}

const mutations = {
  setLoadingState: (state, loadingValue) => {
    state.loading = loadingValue
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
