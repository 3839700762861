import Vue from 'vue'
import Vuex from 'vuex'

import api from '@/store/modules/api'
import loader from '@/store/modules/loader'
import patterns from '@/store/modules/patterns'
import products from '@/store/modules/products'
import sessions from '@/store/modules/sessions'
import transparencyDashboards from '@/store/modules/transparencyDashboards'
import users from '@/store/modules/users'
import usageAnalytics from '@/store/modules/usageAnalytics'
import createPersistedState from 'vuex-persistedstate'
import issues from '@/store/modules/issues'
import demos from '@/store/modules/demos'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({ paths: ['sessions'] })],
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    api,
    issues,
    loader,
    patterns,
    products,
    sessions,
    transparencyDashboards,
    users,
    usageAnalytics,
    demos
  }
})
