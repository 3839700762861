<template>
  <div v-if="loading" id="n-side-loader" class="logo-container is-overlay" :class="{ 'is-fullscreen': fullscreen }">
    <svg class="n-side-loader" viewBox="0 0 246 271" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="n-side-loader--bottom" d="M181.989 183.053C181.989 183.053 184.686 181.496 187.339 183.18L228.511 207.528C228.511 207.528 231.161 209.212 228.446 210.806C228.446 210.806 126.785 269.665 125.516 270.241C124.775 270.574 124.09 270.95 123.267 271V270.974C122.476 270.908 121.803 270.546 121.08 270.219C119.813 269.643 18.152 210.788 18.152 210.788C15.4349 209.192 18.0877 207.508 18.0877 207.508L59.2598 183.16C61.9126 181.474 64.6096 183.033 64.6096 183.033L120.851 215.525C121.799 216.119 122.606 216.408 123.283 216.511V216.535C123.966 216.437 124.785 216.15 125.747 215.545L181.989 183.053Z" fill="#A7B0B5"/>
      <path class="n-side-loader--right" d="M138.656 55.1454C138.656 55.1454 135.959 53.5857 136.091 50.4481L136.601 2.63565C136.601 2.63565 136.738 -0.50193 139.481 1.04779C139.481 1.04779 241.295 59.638 242.429 60.447C243.088 60.9247 243.757 61.3282 244.209 62.0148L244.189 62.0268C244.526 62.7475 244.552 63.5083 244.628 64.3012C244.763 65.6843 244.59 183.096 244.59 183.096C244.568 186.245 241.783 184.79 241.783 184.79L200.105 161.319C197.319 159.866 197.319 156.75 197.319 156.75L197.353 91.8167C197.396 90.7006 197.241 89.8575 196.992 89.2191L197.012 89.2071C196.586 88.6631 195.93 88.103 194.926 87.5691L138.656 55.1454Z" fill="#616A70"/>
      <path class="n-side-loader--left" d="M107.973 55.1415C107.973 55.1415 110.672 53.5817 110.537 50.4442L110.027 2.63174C110.027 2.63174 109.891 -0.505837 107.149 1.04388C107.149 1.04388 5.33576 59.6341 4.19912 60.4431C3.54043 60.9208 2.8717 61.3243 2.41986 62.0109L2.43994 62.0229C2.10256 62.7436 2.07646 63.5044 2.00014 64.2973C1.86559 65.6804 2.0383 183.092 2.0383 183.092C2.0624 186.239 4.84375 184.786 4.84375 184.786L46.5239 161.315C49.3092 159.862 49.3092 156.746 49.3092 156.746L49.2751 91.8128C49.2329 90.6987 49.3876 89.8536 49.6366 89.2152L49.6185 89.2032C50.0422 88.6592 50.6989 88.0971 51.703 87.5651L107.973 55.1415Z" fill="#383E43"/>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    fullscreen: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
}
</script>
