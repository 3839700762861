<template>
  <div id="product-generic" class="columns is-multiline">

    <div class="column error-box" v-if="loadingStatus === 'fail'">
      <div class="box" display: inline-block>
        <p class="center">{{$t(`${baseTranslationPath}.loadingErrorMessage1`, {productName: product.name})}}</p>
        <p class="center">{{$t(`${baseTranslationPath}.loadingErrorMessage2`, {productName: product.name})}}</p>
      </div>
    </div>

    <template v-if="productData">
      <view-wrapper v-for="visu in productData.visualizations"
        :key="visu.key"
        :loading-status="loadingStatus"
        :base-translation-path="`${baseTranslationPath}.${visu.key}`"
        :product="productNameWithViewId(visu)"
        :export-info="exportDataFromVisu(visu)"
      >
        <component
          class="generic-visualization"
          :is="selectView(visu.visualization_type)"
          :view-name="visu.visualization_type"
          :name="product.name"
          :initial-data="visu.data"
          :metadata="visu.metadata"
          :translation-path="`${baseTranslationPath}.${visu.key}`"
          :visu-id="visu.id"
          :refresh-rate="visu.refresh_rate"
        />
      </view-wrapper>
    </template>
  </div>
</template>

<script>
import moment from 'moment'
// Mixins
import { productMixin } from '@/mixins/productMixin'
// Components
import ViewWrapper from '@/components/products/view/ViewWrapper'
import TimeSeriesPlot from '@/components/products/plots/TimeSeriesPlot'
import TimeSeriesBarPlot from '@/components/products/plots/TimeSeriesBarPlot'
import AggregationPlot from '@/components/products/plots/AggregationPlot'
import Table from '@/components/products/table/Table'
import MIPFPerf from '@/components/products/plots/MIPFPerf'
import QIPFPerf from '@/components/products/plots/QIPFPerf'
export default {
  mixins: [productMixin],
  data () {
    return {
      baseTranslationPath: 'views.dashboards.products.generic'
    }
  },
  components: {
    TimeSeriesPlot,
    TimeSeriesBarPlot,
    AggregationPlot,
    Table,
    MIPFPerf,
    QIPFPerf,
    ViewWrapper
  },
  methods: {
    exportDataFromVisu (visu) {
      if (this.productData && visu.data && visu.data.length && visu.data.length > 0) {
        const from = Math.min.apply(Math, visu.data.map(function (o) { return o.datetime }))
        const to = Math.max.apply(Math, visu.data.map(function (o) { return o.datetime }))
        return {
          action: 'products/visualizations/data',
          from: moment(new Date(from)).format('YYYY-MM-DD'),
          to: moment(new Date(to)).format('YYYY-MM-DD')
        }
      } else {
        return null
      }
    },
    selectView (visuType) {
      if (visuType === 'table') {
        return 'Table'
      } else if (visuType === 'ts_plot') {
        return 'TimeSeriesPlot'
      } else if (visuType === 'ts_bar') {
        return 'TimeSeriesBarPlot'
      } else if (visuType === 'aggregated_bar') {
        return 'AggregationPlot'
      } else if (visuType === 'mipf_perf') {
        return 'MIPFPerf'
      } else if (visuType === 'qipf_perf') {
        return 'QIPFPerf'
      } else {
        console.log(`Unknown view type: ${visuType}`)
        return 'Table'
      }
    },
    productNameWithViewId (visu) {
      return {
        name: this.product.name,
        key: visu.id.toString()
      }
    }
  }
}
</script>
