const state = {
  visibility: false
}

const getters = {
  visibility: state => state.visibility
}

const actions = {
  showPatterns: ({ commit }) => {
    commit('setPatternsVisibility', true)
  },
  hidePatterns: ({ commit }) => {
    commit('setPatternsVisibility', false)
  }
}

const mutations = {
  setPatternsVisibility: (state, visibility) => {
    state.visibility = visibility
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
