<template>
  <b-table
    :data="data"
    :bordered="true"
    :striped="true"
    :hoverable="true"
    :narrowed="true"
    :mobile-cards="true"
    :paginated="true"
    :per-page="rowsDisplayed"
  >
    <b-table-column v-for="field in tableFields"
      :key="field.key"
      :field="field.key"
      :label="field.label"
      v-slot="props"
    >
      <template v-if="field.type === typeDatetime">{{ formatDatetime(props.row[field.key]) }}</template>
      <template v-if="field.type === typeTimeTz">{{ formatTimeTz(props.row[field.key]) }}</template>
      <template v-if="field.type === typeNumber">{{ props.row[field.key] }}</template>
      <template v-if="field.type === typeString">{{ props.row[field.key] }}</template>
      <Recommandation v-if="field.type === typeReco" :reco="props.row[field.key]"/>
      <Status v-if="field.type === typeStatus" :status="props.row[field.key]"/>
    </b-table-column>
  </b-table>
</template>

<script>
// Mixins
import { dateMixin } from '@/mixins/dateMixin'
import { fieldsMetadata } from '@/mixins/fieldsMetadata'
import { viewMixin } from '@/mixins/viewMixin'
// Components
import Recommandation from '@/components/products/field/Recommandation'
import Status from '@/components/products/field/Status'
export default {
  mixins: [dateMixin, fieldsMetadata, viewMixin],
  props: {
    rowPerPage: {
      type: Number
    }
  },
  components: {
    Recommandation,
    Status
  },
  data () {
    return {
      showTableExportModal: false,
      currentPage: 1
    }
  },
  computed: {
    rowsDisplayed () {
      if (this.rowPerPage) {
        return this.rowPerPage
      } else {
        return 24
      }
    },
    tableFields () {
      if (this.data && this.data.length) {
        // Get fields metadata by order (ignoring order given by the payload)
        const fieldKeys = Object.keys(this.data[0]).filter(k => k !== 'index')

        let metadataKeys = Object.keys(this.fieldsMetadata)
        // If metadata is defined (externally from backend), use it
        // Additionnaly, if we use this external metadata, sort by ordering_position
        if (this.metadata && Object.keys(this.metadata).length > 0) {
          metadataKeys = Object.keys(this.metadata).sort(
            (md1, md2) => this.metadata[md1].ordering_position - this.metadata[md2].ordering_position
          )
        }
        // If datetime is missing from the provided metadata keys, add it at the beginning
        // This will use the default datetime metadata defined in fieldsMetadata.js
        if (!metadataKeys.includes('datetime')) {
          metadataKeys.unshift('datetime')
        }
        return metadataKeys.filter(k => fieldKeys.includes(k)).map(key => {
          return {
            key: key,
            label: this.getFieldName(key),
            type: this.getFieldType(key)
          }
        })
      } else {
        return []
      }
    }
  }
}
</script>
