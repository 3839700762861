<template>
  <section id="dashboards-view">
    <div class="dashboards-header">
      <PageHeader class="column" :title="$t(`${baseTranslationPath}.title`)" />

      <b-breadcrumb class="column" v-if="selectionReady" separator="has-succeeds-separator">
        <BreadcrumbItem
          placeholder="Country"
          v-model="selectedCountry"
          :choices="countryChoices"
          icon="emojione-monotone:globe-showing-europe-africa"
          @click.native="registerAnalytic('', 'dashboard', 'click_country_select')"
        />
        <BreadcrumbItem
          placeholder="Product"
          v-model="selectedProduct"
          :choices="productChoices"
          icon="codicon:graph"
          @click.native="registerAnalytic('', 'dashboard', 'click_product_select')"
        />
        <BreadcrumbItem
          v-if="viewChoices.length > 0"
          placeholder="View"
          v-model="selectedView"
          :choices="viewChoices"
          icon="carbon:view"
          @click.native="registerAnalytic('', 'dashboard', 'click_view_select')"
        />
      </b-breadcrumb>
    </div>
    <section class="dashboard">
      <router-view v-if="selectedView" :viewData="selectedView.value"></router-view>
      <div class="product-placeholder" v-if="!selectedView && !isLoading">
        <img :src="require('@/assets/images/logos/n-side-gray.svg')" alt="">
        <p class="headings-h5">Select a product to start ...</p>
      </div>
    </section>
    <section>
      <div class="markdown-center" v-if="!selectedView && releaseNoteMarkdown">
        <div class="markdown-left-align" v-if="!selectedView">
          <div class="content" v-html="markdownToHtml"></div>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import moment from 'moment'
// Components
import PageHeader from '@/components/layout/headers/PageHeader'
import BreadcrumbItem from '@/components/BreadcrumbItem'
// Libraries
import { mapGetters } from 'vuex'
import countries from 'i18n-iso-countries'
import _ from 'lodash'
import { usageAnalyticsMixin } from '@/mixins/usageAnalyticsMixin'
import { marked } from 'marked'
// Setup
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))

export default {
  mixins: [usageAnalyticsMixin],
  data () {
    return {
      activeTransparencyDashboards: false,
      baseTranslationPath: 'views.dashboards',
      products: [],
      viewChoices: [],
      selectedCountry: null,
      selectedProduct: null,
      selectedView: null,
      selectionReady: false,
      releaseNoteMarkdown: null
    }
  },
  components: {
    PageHeader,
    BreadcrumbItem
  },
  computed: {
    ...mapGetters({
      user: 'sessions/user',
      isLoading: 'loader/isLoading'
    }),
    countryChoices () {
      return [...new Map(this.products.map(product => {
        return product.countries
      }).map(item => [item.join(), item])).values()].sort((cA, cB) => this.countriesOrderFunction(cA, cB)).map(c => {
        return {
          key: c.join('-'),
          value: c,
          label: this.countriesDisplayName(c)
        }
      })
    },
    productChoices () {
      if (this.selectedCountry) {
        return this.products.filter(product => {
          return _.isEqual(product.countries, this.selectedCountry.value)
        }).sort((pA, pB) => this.productsOrderFunction(pA, pB)).map(p => {
          return {
            key: p.key,
            value: p,
            label: p.name
          }
        })
      } else {
        return []
      }
    },
    markdownToHtml () {
      return marked(this.releaseNoteMarkdown)
    }
  },
  methods: {
    countriesDisplayName (countriesArray) {
      return countriesArray.map(country => {
        return countries.getName(country, this.user.locale, { select: 'official' })
      }).join('-')
    },
    countriesOrderFunction (cA, cB) {
      return this.countriesDisplayName(cA).charAt(0).localeCompare(this.countriesDisplayName(cB).charAt(0))
    },
    productsOrderFunction (pA, pB) {
      return pA.name.charAt(0).localeCompare(pB.name.charAt(0))
    },
    resetSelection () {
      this.selectedCountry = null
      this.selectedProduct = null
      this.selectedView = null
    },
    updateViewChoices () {
      if (this.selectedProduct) {
        const stdView = [{
          key: 'home',
          value: {
            route: 'home',
            product: this.selectedProduct.value
          },
          label: this.$t(`${this.baseTranslationPath}.views.home`)
        }]
        if (this.selectedProduct.value.has_transparency_dashboards) {
          return this.$store.dispatch('products/transparencyDashboards/index', this.selectedProduct.value.key)
            .then(response => {
              // TODO remove this useless shape on backend: "[0].available_periods"
              const tdViews = response.data[0].available_periods.map(td => {
                const from = moment(td.period_start).format('DD-MM-YYYY hh')
                const name = `TD from ${from}h`
                return {
                  key: name,
                  value: {
                    route: 'transparency',
                    dashboard: td,
                    product: this.selectedProduct.value
                  },
                  label: name
                }
              })
              this.viewChoices = stdView.concat(tdViews)
            })
            .catch(error => { console.log(error) })
        } else {
          this.viewChoices = stdView
          return Promise.resolve(this.viewChoices)
        }
      } else {
        this.viewChoices = []
        return Promise.resolve(this.viewChoices)
      }
    },
    updateRouter () {
      if (this.selectedView) {
        const product = this.selectedProduct.value
        const newPath = `/dashboards/${product.classname}/${product.key}/${this.selectedView.value.route}`
        // Debounce useless updates
        if (this.$router.currentRoute.path !== newPath) {
          this.$router.push(newPath)
        }
      }
    }
  },
  watch: {
    selectedProduct (_) {
      if (this.selectionReady) {
        this.updateViewChoices()
      }
    },
    selectedView (_) {
      if (this.selectionReady) {
        this.updateRouter()
      }
    }
  },
  mounted () {
    this.resetSelection()

    this.$store.dispatch('products/releaseNotes/get')
      .then(response => {
        this.releaseNoteMarkdown = response.data.content
      }).catch(error => { console.log(error) })

    this.$store.dispatch('products/index')
      .then(response => {
        this.products = response.data
        if (this.$route.params.product && this.$route.params.productKey) {
          const res = _.find(this.products, product => product.key === this.$route.params.productKey)
          if (res) {
            this.selectedCountry = {
              key: res.countries.join('-'),
              value: res.countries,
              label: this.countriesDisplayName(res.countries)
            }
            this.selectedProduct = {
              key: res.key,
              value: res,
              label: res.name
            }
            this.updateViewChoices().then(() => { this.selectionReady = true }).then(() => {
              this.selectedView = { // TODO: redirect toward the right view
                key: 'home',
                value: {
                  route: 'home',
                  product: this.selectedProduct.value
                },
                label: this.$t(`${this.baseTranslationPath}.views.home`)
              }
            })
          } else {
            this.$router.push('/404')
          }
        } else {
          this.selectionReady = true
        }
      })
      .catch(error => { console.log(error) })
  }
}
</script>
