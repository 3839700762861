<template>
  <section id="account-settings">
    <SectionHeader :title="$t(`${baseTranslationPath}.accountSettings.title`)" :subtitle="$t(`${baseTranslationPath}.accountSettings.subtitle`)"/>

    <div class="section-content">
      <b-field :label="$t(`${baseTranslationPath}.accountSettings.apiKey`)" :message="$t(`${baseTranslationPath}.accountSettings.apiKeyMessage`)">
        <b-input expanded disabled placeholder="Email" :value="`Token ${apiKey}`" icon-right="clipboard-multiple" icon-right-clickable @icon-right-click="copyKeyToClipboard"></b-input>
        <p class="control">
          <b-button @click="resetApiKey" class="button is-danger" icon-right="restore">Reset Key</b-button>
        </p>
      </b-field>
    </div>
    <transition enter-active-class="fadeIn" leave-active-class="fadeOut">
      <UpdatePasswordModal v-if="showUpdatePasswordModal" @close="showUpdatePasswordModal = false"/>
    </transition>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import SectionHeader from '@/components/layout/headers/SectionHeader'
import UpdatePasswordModal from '@/views/Settings/UpdatePasswordModal'
export default {
  data () {
    return {
      baseTranslationPath: 'views.settings.sections.account',
      showUpdatePasswordModal: false,
      revealedApiKey: false,
      apiKey: null
    }
  },
  computed: {
    ...mapGetters({
      user: 'sessions/user'
    })
  },
  components: {
    SectionHeader,
    UpdatePasswordModal
  },
  methods: {
    updateApiKey () {
      this.$store.dispatch('users/apiKey/update', this.user.id)
        .then(response => {
          this.apiKey = response.data
          this.$buefy.toast.open({
            type: 'is-success',
            message: this.$t(`${this.baseTranslationPath}.apiKeyReset`)
          })
        })
        .catch(error => { console.log(error) })
    },
    copyKeyToClipboard () {
      navigator.clipboard.writeText(`Token ${this.apiKey}`)
      this.$buefy.toast.open({
        message: 'Copied to clipboard!'
      })
    },
    resetApiKey () {
      this.$buefy.dialog.confirm({
        type: 'is-danger',
        title: this.$t(`${this.baseTranslationPath}.resetApiKeyModal.title`),
        message: this.$t(`${this.baseTranslationPath}.resetApiKeyModal.body`),
        confirmMessage: this.$t(`${this.baseTranslationPath}.resetApiKeyModal.confirm`),
        cancelMessage: this.$t(`${this.baseTranslationPath}.resetApiKeyModal.cancel`),
        onConfirm: () => this.updateApiKey()
      })
    }
  },
  created () {
    this.$store.dispatch('users/apiKey/get', this.user.id)
      .then(resp => { this.apiKey = resp.data })
      .catch(error => { console.log(error) })
  }
}
</script>
