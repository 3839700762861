<template>
  <section>
    <div id="viewport" class="container is-fluid">
      <Navbar v-if="isAuthenticated"/>
      <router-view/>
    </div>
    <Loader id="global-loader" :loading="loaderState" :fullscreen="true"/>
    <Patterns/>
  </section>
</template>

<script>
import Navbar from '@/components/layout/Navbar'
import Patterns from '@/components/layout/Patterns'
import { mapGetters } from 'vuex'
import Loader from '@/components/Loader'
export default {
  components: {
    Navbar,
    Patterns,
    Loader
  },
  computed: {
    ...mapGetters({
      loaderState: 'loader/isLoading',
      isAuthenticated: 'sessions/isAuthenticated'
    })
  },
  mounted () {
    this.$store.dispatch('issues/fetchAndDisplayGeneralIssues')
  }
}
</script>
