import { ToastProgrammatic as Toast } from 'buefy'
const DEFAULT_TOAST_DURATION = 2000
export const notificationMixin = {
  methods: {
    toastSuccess (message, duration = DEFAULT_TOAST_DURATION) {
      Toast.open({
        duration: duration,
        message: `${message} <span class="icon"><i class="mdi mdi-check-circle-outline"></i></span>`,
        position: 'is-top',
        type: 'is-success'
      })
    },
    toastError (message, duration = DEFAULT_TOAST_DURATION) {
      Toast.open({
        duration: duration,
        message: `${message} <span class="icon"><i class="mdi mdi-close-octagon"></i></span>`,
        position: 'is-top',
        type: 'is-danger'
      })
    }
  }
}
