var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.currentUser)?_c('b-navbar',{attrs:{"aria-label":"main navigation"},scopedSlots:_vm._u([{key:"brand",fn:function(){return [_c('b-navbar-item',{attrs:{"tag":"router-link","to":{ name: 'dashboards' }}},[_c('img',{staticClass:"nefp-logo",attrs:{"src":require('@/assets/images/logos/nefp.svg'),"width":"160","alt":_vm.$t('general.enerforLogoAlt')}})])]},proxy:true},{key:"end",fn:function(){return [_c('router-link',{attrs:{"to":{ name: 'dashboards'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navbar-item headings-h5 center-right-icon",class:{active: isActive},on:{"click":navigate}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.dashboards')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"carbon:dashboard"}})],1)])])]}}],null,false,1822286470)}),(_vm.currentUser.has_demos)?_c('router-link',{attrs:{"to":{ name: 'demos'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navbar-item headings-h5 center-right-icon",class:{active: isActive},on:{"click":navigate}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.demos')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"carbon:analytics-custom"}})],1)])])]}}],null,false,2564763917)}):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'api'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navbar-item headings-h5 center-right-icon",class:{active: isActive},on:{"click":navigate}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.api')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"bi:journal-code"}})],1)])])]}}],null,false,484084500)}),_c('b-navbar-item',{attrs:{"href":_vm.currentUser.service_desk_link}},[_c('a',{staticClass:"headings-h5 center-right-icon",class:{active: _vm.isActive}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.serviceDesk')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"carbon:service-desk"}})],1)])])]),(_vm.currentUser.is_staff)?_c('b-navbar-item',{attrs:{"href":_vm.adminPanelUrl}},[_c('a',{staticClass:"headings-h5 center-right-icon",class:{active: _vm.isActive}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.admin')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"ic:baseline-admin-panel-settings"}})],1)])])]):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'applicationSettings'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navbar-item headings-h5 center-right-icon",class:{active: isActive},on:{"click":navigate}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.settings')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"carbon:settings"}})],1)])])]}}],null,false,2679465109)}),_c('router-link',{attrs:{"to":{ name: 'logout'},"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [_c('a',{staticClass:"navbar-item headings-h5 center-right-icon",class:{active: isActive},on:{"click":navigate}},[_c('span',{staticClass:"icon-text"},[_c('span',[_vm._v(_vm._s(_vm.$t('navbar.item.logout')))]),_c('span',{staticClass:"icon"},[_c('Icon',{attrs:{"icon":"mdi:logout"}})],1)])])]}}],null,false,4174760230)})]},proxy:true}],null,false,4195099011)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }