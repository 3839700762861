<template>
  <b-breadcrumb-item v-if="choices.length > 0" :active="choices.length <= 1">
    <Icon v-if="icon" :icon="icon" />
    <template v-if="choices.length == 1">
      {{ choices[0].label }}
    </template>
    <template v-if="choices.length > 1">
      <b-dropdown aria-role="list" @input="handleInput">
        <template #trigger>
          <template v-if="!content">{{ placeholder }}</template>
          <template v-else>{{ content.label }}</template>
          <Icon icon="ri:arrow-drop-down-line" />
        </template>
        <b-dropdown-item v-for="(choice, index) in choices" :key="index" aria-role="listitem" :value="choice.key">
          {{ choice.label }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-breadcrumb-item>
</template>

<script>
import { Icon } from '@iconify/vue2'
import _ from 'lodash'
export default {
  props: {
    placeholder: {
      type: String
    },
    choices: { // [{key: <choiceKey: String>, value: <choiceValue: Any>,label: <choiceLabel: String>}, ...]
      type: Array
    },
    value: {}, // {key: <choiceKey: String>, value: <choiceValue: Any>,label: <choiceLabel: String>}
    icon: {
      type: String,
      default: null
    }
  },
  components: {
    Icon
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    choicesMap () {
      return new Map(this.choices.map(c => {
        return [c.key, c]
      }))
    }
  },
  methods: {
    handleInput (e) {
      this.content = this.choicesMap.get(e)
      this.updateEvent()
    },
    updateEvent () {
      if (!this.content) {
        this.$emit('input', null)
      } else {
        this.$emit('input', this.content)
      }
    }
  },
  watch: {
    choices (newChoices, _oldChoices) {
      // If only one choice, put the content to the only possible option
      if (newChoices && newChoices.length === 1) {
        this.content = newChoices[0]
        this.updateEvent()
      // If current content in the new choices, no event and no change
      } else if (newChoices.find(c => { return _.isEqual(this.content, c) })) {
        // Do nothing
      // If other choices, re-init content and trigger event
      } else {
        this.content = null
        this.updateEvent()
      }
    }
  },
  mounted () {
    if (this.choices.length === 1) {
      this.content = this.choices[0]
      this.updateEvent()
    }
  }
}
</script>
