<template>
  <section class="transparency-dashboard">
    <section class="is-clearfix">
      <div class="is-pulled-left left-menu">
        <PageHeader title="Transparency Dashboards" subtitle="Transparency dashboards are meta informations about our forecasting algorithm and explains in more details the recommendation process. Select a model and applied period to obtain more information."/>
      </div>
    </section>
    <div class="columns is-multiline">
      <div v-for="(dashboard, index) in dashboardData" class="column" :class="`is-${dashboard.width}`" :key="index">
        <Plotly  :layout="dashboard.figure.layout" :data="dashboard.figure.data"/>
      </div>
    </div>
  </section>
</template>

<script>
import PageHeader from '@/components/layout/headers/PageHeader'
import { dateMixin } from '@/mixins/dateMixin'
import Plotly from '@/components/Plotly'
import { browsingAnalyticMixin } from '@/mixins/browsingAnalyticMixin'
export default {
  props: ['viewData'],
  mixins: [dateMixin, browsingAnalyticMixin],
  data () {
    return {
      dashboardData: null
    }
  },
  components: {
    PageHeader,
    Plotly
  },
  computed: {
    analyticsInfos () {
      return {
        product: this.viewData.dashboard.model_key,
        view: 'transparency_dashboard',
        optional_values: {
          modelId: this.viewData.dashboard.model_id,
          from: this.viewData.dashboard.period_start,
          to: this.viewData.dashboard.period_end
        }
      }
    }
  },
  methods: {
    loadDashboardData () {
      this.dashboardData = null
      const payload = {
        modelKey: this.viewData.dashboard.model_key,
        modelId: this.viewData.dashboard.model_id,
        from: this.viewData.dashboard.period_start,
        to: this.viewData.dashboard.period_end
      }
      this.$store.dispatch('loader/enableLoading')
      this.$store.dispatch('transparencyDashboards/get', payload)
        .then(response => { this.dashboardData = response.data })
        .catch(error => { console.log(error) })
        .finally(() => { this.$store.dispatch('loader/disableLoading') })
    }
  },
  mounted () {
    this.loadDashboardData()
  },
  watch: {
    viewData (newVD, oldVD) {
      // Debounce and avoid first load
      if (newVD !== oldVD && oldVD) {
        this.loadDashboardData()
        this.updateBrowsingAnalytics()
      }
    }
  }
}
</script>
