<template>
  <div class="column" v-if="loadingStatus === 'success'">
    <div class="box">
      <div class="level">
        <div class="level-left">
          <!-- Title and subtitle from locales -->
          <SectionHeader
            :title="$t(`${baseTranslationPath}.title`, { productName: product.name})"
            :subtitle="$t(`${baseTranslationPath}.subtitle`, { productName: product.name})"
          />
        </div>
        <div v-if="exportInfo" class="level-right">
          <b-button @click="showExportModal = true" type="is-primary" icon-left="file-export">
            {{ $t('buttons.export' )}}
          </b-button>
        </div>
      </div>
      <slot/>
    </div>
    <b-modal :active.sync="showExportModal">
      <DataExportModal
        v-if="exportInfo"
        :title="$t(`${baseTranslationPath}.exportModal.title`)"
        :subtitle="$t(`${baseTranslationPath}.exportModal.subtitle`, { productName: product.name })"
        :action="exportInfo.action"
        :product-key="product.key"
        :from="exportInfo.from"
        :to="exportInfo.to"
        @close="showExportModal = false"
      />
    </b-modal>
  </div>
</template>

<script>
// Components
import DataExportModal from '@/components/modals/DataExportModal'
import SectionHeader from '@/components/layout/headers/SectionHeader'
export default {
  components: {
    SectionHeader,
    DataExportModal
  },
  props: {
    loadingStatus: {
      type: String,
      required: true
    },
    baseTranslationPath: {
      type: String,
      required: true
    },
    product: { // Should be in the shape of: {key: <String>, name: <String>, ...}
      type: Object,
      required: true
    },
    exportInfo: {
      // If defined, should be in the shape of: {
      //   action: <String>,
      //   from: <String>,
      //   to: <String>
      // }
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showExportModal: false
    }
  }
}
</script>
