<template>
  <section id="dashboards-view">
    <div class="dashboards-header">
      <PageHeader class="column" :title="$t(`${baseTranslationPath}.title`)" />

      <b-breadcrumb class="column" separator="has-succeeds-separator">
        <BreadcrumbItem
          placeholder="Demo"
          v-model="selectedDemo"
          :choices="demoChoices"
          icon="emojione-monotone:globe-showing-europe-africa"
        />
      </b-breadcrumb>
    </div>
    <section class="dashboard">
      <component v-if="selectedDemo" :is="selectedDemo.component"/>
      <div class="product-placeholder" v-if="!selectedDemo">
        <img :src="require('@/assets/images/logos/n-side-gray.svg')" alt="">
        <p class="headings-h5">Select a demo to start ...</p>
      </div>
    </section>
  </section>
</template>

<script>
// Components
import PageHeader from '@/components/layout/headers/PageHeader'
import BreadcrumbItem from '@/components/BreadcrumbItem'
// Libraries
import { mapGetters } from 'vuex'
import countries from 'i18n-iso-countries'
import mfrrActivation from '@/views/Demos/mfrrActivation/index'
import inputSensitivity from '@/views/Demos/inputSensitivity/index'
// Setup
countries.registerLocale(require('i18n-iso-countries/langs/en.json'))
countries.registerLocale(require('i18n-iso-countries/langs/fr.json'))

export default {
  mixins: [],
  data () {
    return {
      baseTranslationPath: 'views.demos',
      selectedDemo: null,
      availableComponents: {
        mfrractivation: 'mfrrActivation',
        inputsensitivity: 'inputSensitivity'
      },
      demoChoices: []
    }
  },
  components: {
    PageHeader,
    BreadcrumbItem,
    mfrrActivation,
    inputSensitivity
  },
  computed: {
    ...mapGetters({
      user: 'sessions/user',
      isLoading: 'loader/isLoading'
    })
  },
  methods: {

    resetSelection () {
      this.selectedDemo = null
    },
    getDemoChoiceWithKey (key) {
      const matched = this.demoChoices.filter(demoChoice => demoChoice.key === key)
      if (matched) {
        return matched[0]
      } else {
        return null
      }
    },
    updateRouter () {
      if (this.selectedDemo) {
        const demo = this.selectedDemo
        const newPath = `/demos/${demo.key}`
        // Debounce useless updates
        if (this.$router.currentRoute.path !== newPath) {
          this.$router.push(newPath)
        }
      }
    }

  },
  watch: {
    selectedDemo (_nu, _old) {
      this.updateRouter()
    }
  },
  mounted () {
    this.$store.dispatch('demos/index')
      .then(resp => {
        // Load demo choices for this user from backend
        this.demoChoices = resp.map(d => {
          return {
            key: d.key,
            label: d.name,
            component: this.availableComponents[d.key]
          }
        })
      }).then(_ => {
        // Optionally load demo from url
        const url = this.$route.params.key
        this.resetSelection()
        const demoChoice = this.getDemoChoiceWithKey(url)
        if (demoChoice) {
          this.selectedDemo = demoChoice
        }
      })
  }
}
</script>
