var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"login-view"}},[_c('section',[_c('div',{staticClass:"login-box box"},[_c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require('@/assets/images/logos/nefp.svg'),"alt":_vm.$t('general.enerforLogoAlt')}})]),_c('div',{staticClass:"header"},[_vm._v(" Login or request an account to start using the N-SIDE Energy Forecasting Platform service. ")]),_c('hr'),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('div',{staticClass:"form",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return handleSubmit(_vm.login)}}},[_c('ValidationProvider',{attrs:{"slim":"","rules":"required","name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('inputs.username.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('p',{staticClass:"control has-icons-left"},[_c('b-input',{attrs:{"name":"username","placeholder":_vm.$t('inputs.username.placeholder')},model:{value:(_vm.credentials.username),callback:function ($$v) {_vm.$set(_vm.credentials, "username", $$v)},expression:"credentials.username"}}),_c('span',{staticClass:"icon is-small is-left"},[_c('Icon',{attrs:{"icon":"fluent:inprivate-account-16-regular"}})],1)],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"slim":"","rules":"required","name":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t('inputs.password.label'),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('p',{staticClass:"control has-icons-left"},[_c('b-input',{attrs:{"name":"password","type":"password","placeholder":_vm.$t('inputs.password.placeholder')},model:{value:(_vm.credentials.password),callback:function ($$v) {_vm.$set(_vm.credentials, "password", $$v)},expression:"credentials.password"}}),_c('span',{staticClass:"icon is-small is-left"},[_c('Icon',{attrs:{"icon":"ic:baseline-password"}})],1)],1)])]}}],null,true)}),_c('b-button',{attrs:{"name":"submit","disabled":!valid,"expanded":"","type":"is-primary"},on:{"click":function($event){return handleSubmit(_vm.login)}}},[_vm._v(_vm._s(_vm.$t('buttons.login')))])],1)]}}])})],1)]),_vm._m(0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"footer"},[_c('div',{staticClass:"content has-text-centered"},[_c('p',{staticClass:"text-label"},[_vm._v(" Copyright © 2021-2022 N-SIDE. All rights reserved. ")])])])}]

export { render, staticRenderFns }