// This mixin concentrate the common logic of product components
import { usageAnalyticsMixin } from '@/mixins/usageAnalyticsMixin'

export const viewMixin = {
  mixins: [usageAnalyticsMixin],
  props: {
    name: {
      type: String,
      required: true
    },
    viewName: {
      type: String,
      required: true
    },
    translationPath: {
      type: String,
      required: true
    },
    initialData: {
      type: Array,
      required: true
    },
    visuId: {
      type: Number,
      required: true
    },
    refreshRate: {
      type: Number,
      required: false
    }
  },
  data () {
    return {
      data: this.initialData
    }
  },
  watch: {
    initialData (newInitialData, _) {
      this.data = newInitialData
    }
  },
  methods: {
    triggerWindowResize () {
      window.dispatchEvent(new Event('resize'))
    },
    refreshData () {
      this.$store.dispatch('products/visualizations/data', { productKey: this.visuId })
        .then(response => {
          this.data = response.data.data
        })
    },
    setRefreshData () {
      if (this.refreshRate) {
        setInterval(this.refreshData, this.refreshRate * 1000)
      }
    }
  },
  mounted () {
    this.setRefreshData()
    this.registerAnalytic(this.name, this.viewName, 'loaded')
  }
}
