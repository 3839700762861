var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-card",staticStyle:{"width":"auto"}},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title headings-h3"},[_vm._v(_vm._s(_vm.$t((_vm.baseTranslationPath + ".modalTitle"))))]),_c('button',{staticClass:"delete",attrs:{"type":"button"},on:{"click":function($event){return _vm.$emit('close')}}})]),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var valid = ref.valid;
return [_c('section',{staticClass:"modal-card-body"},[_c('ValidationProvider',{attrs:{"slim":"","rules":"required","name":"username"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t((_vm.baseTranslationPath + ".oldPassword")),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('p',{staticClass:"control has-icons-left"},[_c('b-input',{attrs:{"type":"password","name":"oldPassword","placeholder":"***********"},model:{value:(_vm.oldPassword),callback:function ($$v) {_vm.oldPassword=$$v},expression:"oldPassword"}}),_c('span',{staticClass:"icon is-small is-left"},[_c('Icon',{attrs:{"icon":"ic:baseline-password"}})],1)],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"vid":"newPassword","slim":"","rules":"required|password","name":"newPassword"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t((_vm.baseTranslationPath + ".newPassword")),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('p',{staticClass:"control has-icons-left"},[_c('b-input',{attrs:{"type":"password","name":"newPassword","placeholder":"***********"},model:{value:(_vm.newPassword),callback:function ($$v) {_vm.newPassword=$$v},expression:"newPassword"}}),_c('span',{staticClass:"icon is-small is-left"},[_c('Icon',{attrs:{"icon":"ic:baseline-password"}})],1)],1)])]}}],null,true)}),_c('ValidationProvider',{attrs:{"slim":"","rules":"required|password|confirmed:newPassword","name":"newPasswordConfirmation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var valid = ref.valid;
return [_c('b-field',{attrs:{"label":_vm.$t((_vm.baseTranslationPath + ".newPasswordConfirmation")),"type":{ 'is-danger': errors[0], 'is-success': valid },"message":errors}},[_c('p',{staticClass:"control has-icons-left"},[_c('b-input',{attrs:{"type":"password","name":"newPasswordConfirmation","placeholder":"***********"},model:{value:(_vm.newPasswordConfirmation),callback:function ($$v) {_vm.newPasswordConfirmation=$$v},expression:"newPasswordConfirmation"}}),_c('span',{staticClass:"icon is-small is-left"},[_c('Icon',{attrs:{"icon":"ic:baseline-password"}})],1)],1)])]}}],null,true)})],1),_c('footer',{staticClass:"modal-card-foot"},[_c('b-button',{attrs:{"label":"Cancel"},on:{"click":function($event){return _vm.$emit('close')}}}),_c('b-button',{attrs:{"disabled":!valid,"label":"Update my password","type":"is-primary"},on:{"click":function($event){return handleSubmit(_vm.updatePassword)}}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }