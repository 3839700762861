<template>
  <component :is="componentName" :product="viewData.product" :productData="productData" :loadingStatus="loadingStatus"/>
</template>

<script>
// TODO: rename this or just remove this layer once generic product is the only one
import genericproductProduct from '@/components/products/generic'
import { browsingAnalyticMixin } from '@/mixins/browsingAnalyticMixin'
export default {
  props: ['viewData'],
  mixins: [browsingAnalyticMixin],
  data () {
    return {
      productData: null,
      loadingStatus: 'none'
    }
  },
  watch: {
    viewData (newVal, _oldVal) {
      if (newVal) {
        this.loadProduct()
      }
    }
  },
  mounted () {
    this.loadProduct()
  },
  components: {
    // Products
    genericproductProduct
  },
  computed: {
    componentName () {
      return `${this.viewData.product.classname}Product`
    },
    analyticsInfos () {
      return {
        product: this.viewData.product.key,
        view: 'home'
      }
    }
  },
  methods: {
    loadProduct () {
      const payload = {
        productType: this.viewData.product.classname,
        productKey: this.viewData.product.key
      }
      this.loadingStatus = 'none'

      this.$store.dispatch('loader/enableLoading')
      this.$store.dispatch('products/get', payload)
        .then(response => {
          this.loadingStatus = 'success'
          this.productData = response.data
        })
        .catch(error => {
          if (payload.productType !== undefined) {
            this.loadingStatus = 'fail'
          }
          this.productData = null
          console.log(error)
        })
        .finally(() => { this.$store.dispatch('loader/disableLoading') })
    }
  }
}
</script>
