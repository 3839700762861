import Vue from 'vue'
import i18n from '@/i18n'
import { SnackbarProgrammatic as Snackbar } from 'buefy'

const buefySeverity = (severity) => {
  switch (severity) {
    case 'WARN':
      return 'is-warning'
    case 'DNGR':
      return 'is-danger'
    default:
      return 'is-info'
  }
}

const actions = {
  fetchAndDisplayGeneralIssues: ({ dispatch }) => {
    Vue.prototype.$enerforApi.get('issues')
      .then(resp => {
        resp.data.forEach(issue => {
          dispatch('displayIssue', { issue: issue, position: 'is-top' })
        })
      })
      .catch(_ => {
        // The server was not reachable, add general message for the user
        dispatch('displayIssue', { issue: { message: i18n.t('globalMessages.serverError'), severity: 'DNGR' }, position: 'is-top' })
      })
  },
  displayIssue: ({ _commit }, { issue, position }) => {
    Snackbar.open(({
      message: issue.message,
      type: buefySeverity(issue.severity),
      position: position,
      actionText: 'Close',
      indefinite: true,
      queue: false
    }))
  }
}

export default {
  namespaced: true,
  actions
}
