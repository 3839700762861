import { required, confirmed } from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import i18n from './i18n'

extend('required', {
  ...required,
  message: i18n.tc('vee-validate.required')
})

extend('password', {
  validate (value) {
    return value.length >= 12 && !/^\d+$/.test(value)
  },
  message: i18n.tc('vee-validate.password')
})

extend('confirmed', {
  ...confirmed,
  message: i18n.tc('vee-validate.confirmed')
})
