<template>
  <TimeSeriesPlot
    :name="name"
    :data="data"
    :translation-path="translationPath"
    :initial-data="initialData"
    :visu-id="visuId"
    :refresh-rate="refreshRate"
    plot-type="bar"
  />
</template>

<script>
// Components
import TimeSeriesPlot from '@/components/products/plots/TimeSeriesPlot'
// Mixins
import { viewMixin } from '@/mixins/viewMixin'

export default {
  mixins: [viewMixin],
  components: {
    TimeSeriesPlot
  }
}
</script>
