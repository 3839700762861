
const state = {
  connection: null,
  wsReconnectionTimerId: null,
  messageQueue: []
}

const getters = {
  connection: state => state.connection,
  wsReconnectionTimerId: state => state.wsReconnectionTimerId
}

const actions = {
  connect: ({ state, commit, rootState, dispatch }, _credentials) => {
    const addr = process.env.VUE_APP_ENERFOR_BACKEND_HOST.replace('http', 'ws')
    const con = new WebSocket(`${addr}/ws/ua?token=${rootState.sessions.accessToken}`)
    con.onopen = (_event) => {
      commit('setConnection', con)
      console.debug('Websocket connected')
      commit('emptyMessageQueue')
      console.debug('Message queue emptied')
      clearInterval(state.wsReconnectionTimerId)
      commit('setWsReconnectionTimerId', null)
    }
    con.onmessage = (event) => {
      console.log(event)
    }
    con.onclose = (_event) => {
      console.log('Socket connection lost.')
      if (!state.wsReconnectionTimerId) {
        const wsReconnectionTimerId = setInterval(() => {
          console.log('Trying to reconnect the socket.')
          dispatch('connect')
        }, 10000)
        commit('setWsReconnectionTimerId', wsReconnectionTimerId)
      }
    }
    con.onerror = (event) => {
      console.debug('Socket encountered error: ', event, 'Closing socket')
      // This error might be due to an outdated access token, we therefore throw a tentative to revive it before
      // assuming a server or network issue
      dispatch('sessions/refreshToken', null, { root: true })
        .finally(_ => con.close())
    }
  },
  disconnect: ({ state, commit }) => {
    if (state.connection) {
      state.connection.close()
      commit('setConnection', null)
      console.debug('Websocket disconnected.')
    }
  },
  sendEvent: ({ state, commit }, event) => {
    const jsonEvent = JSON.stringify({ event: event })
    if (state.connection) {
      state.connection.send(jsonEvent)
    } else {
      commit('pushInMessageQueue', jsonEvent)
    }
  }
}

const mutations = {
  setConnection: (state, con) => {
    state.connection = con
  },
  setWsReconnectionTimerId: (state, wsReconnectionTimerId) => {
    state.wsReconnectionTimerId = wsReconnectionTimerId
  },
  emptyMessageQueue: (state) => {
    const queue = [...state.messageQueue]
    state.messageQueue = []
    queue.forEach(message => {
      state.connection.send(message)
    })
  },
  pushInMessageQueue: (state, jsonEvent) => {
    state.messageQueue.push(jsonEvent)
  }
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations
}
