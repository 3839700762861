import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import Buefy from 'buefy'
import EnerforApi from '@/plugins/enerforApi'
import './vee-validate'

import '@/assets/stylesheets/main.scss'

Vue.config.productionTip = false

Vue.prototype.$enerforApi = EnerforApi()

Vue.use(Buefy)

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// Ensure a socket connection is tried at app start if the user is already logged in
if (store.state.sessions.accessToken) {
  store.dispatch('usageAnalytics/connect').then(_ => ({}))
}
