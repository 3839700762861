<template>
  <section>
    <div class="field is-grouped">
      <b-breadcrumb separator="has-bullet-separator">
        <BreadcrumbItem
          :placeholder="$t(`${translationPath}.marginalCostPos`)"
          v-model="marginalPos"
          :choices="marginalPosChoices"
        />
        <BreadcrumbItem
          :placeholder="$t(`${translationPath}.marginalCostNeg`)"
          v-model="marginalNeg"
          :choices="marginalNegChoices"
        />
        <BreadcrumbItem
          :placeholder="$t(`${translationPath}.triggerMargin`)"
          v-model="triggerMargin"
          :choices="triggerMarginChoices"
        />
        <BreadcrumbItem
          :placeholder="$t(`${translationPath}.metric`)"
          v-model="metric"
          :choices="metricChoices"
        />
      </b-breadcrumb>
    </div>
    <Plotly :layout="plotlyLayout" :data="plotlyData" />
  </section>
</template>

<script>
// Components
import Plotly from '@/components/Plotly'
import BreadcrumbItem from '@/components/BreadcrumbItem'
// Mixins
import { fieldsMetadata } from '@/mixins/fieldsMetadata'
import { viewMixin } from '@/mixins/viewMixin'

export default {
  mixins: [fieldsMetadata, viewMixin],
  components: {
    Plotly,
    BreadcrumbItem
  },
  props: {
    plotType: {
      type: String
    }
  },
  data () {
    return {
      marginalPos: { key: 'p35', value: 'p35', label: 'Marginal cost (POS) of 35 €/MWh' },
      marginalNeg: { key: 'm5', value: 'm5', label: 'Marginal cost (NEG) of 5 €/MWh' },
      triggerMargin: { key: '0', value: '0', label: 'Trigger margin of 0 €/MWh' },
      metric: { key: 'Cum', value: 'Cum', label: 'Metric: Cumulative savings' },
      marginalPosChoices: [
        { key: 'p35', value: 'p35', label: 'Marginal cost (POS) of 35 €/MWh' },
        { key: 'p45', value: 'p45', label: 'Marginal cost (POS) of 45 €/MWh' },
        { key: 'p60', value: 'p60', label: 'Marginal cost (POS) of 60 €/MWh' },
        { key: 'p80', value: 'p80', label: 'Marginal cost (POS) of 80 €/MWh' },
        { key: 'p100', value: 'p100', label: 'Marginal cost (POS) of 100 €/MWh' },
        { key: 'p125', value: 'p125', label: 'Marginal cost (POS) of 125 €/MWh' },
        { key: 'p150', value: 'p150', label: 'Marginal cost (POS) of 150 €/MWh' },
        { key: 'p200', value: 'p200', label: 'Marginal cost (POS) of 200 €/MWh' },
        { key: 'p250', value: 'p250', label: 'Marginal cost (POS) of 250 €/MWh' },
        { key: 'p300', value: 'p300', label: 'Marginal cost (POS) of 300 €/MWh' },
        { key: 'p350', value: 'p350', label: 'Marginal cost (POS) of 350 €/MWh' },
        { key: 'p400', value: 'p400', label: 'Marginal cost (POS) of 400 €/MWh' },
        { key: 'p450', value: 'p450', label: 'Marginal cost (POS) of 450 €/MWh' },
        { key: 'p500', value: 'p500', label: 'Marginal cost (POS) of 500 €/MWh' },
        { key: 'p550', value: 'p550', label: 'Marginal cost (POS) of 550 €/MWh' },
        { key: 'p600', value: 'p600', label: 'Marginal cost (POS) of 600 €/MWh' },
        { key: 'p650', value: 'p650', label: 'Marginal cost (POS) of 650 €/MWh' },
        { key: 'p700', value: 'p700', label: 'Marginal cost (POS) of 700 €/MWh' }
      ],
      marginalNegChoices: [
        { key: 'm5', value: 'm5', label: 'Marginal cost (NEG) of 5 €/MWh' },
        { key: 'p0', value: 'p0', label: 'Marginal cost (NEG) of 0 €/MWh' },
        { key: 'p10', value: 'p10', label: 'Marginal cost (NEG) of 10 €/MWh' },
        { key: 'p20', value: 'p20', label: 'Marginal cost (NEG) of 20 €/MWh' },
        { key: 'p25', value: 'p25', label: 'Marginal cost (NEG) of 25 €/MWh' },
        { key: 'p35', value: 'p35', label: 'Marginal cost (NEG) of 35 €/MWh' }
      ],
      triggerMarginChoices: [
        { key: '0', value: '0', label: 'Trigger margin of 0 €/MWh' },
        { key: '10', value: '10', label: 'Trigger margin of 10 €/MWh' },
        { key: '20', value: '20', label: 'Trigger margin of 20 €/MWh' }
      ],
      metricChoices: [
        { key: 'Cum', value: 'Cum', label: 'Metric: Cumulative savings' },
        { key: 'week', value: 'week', label: 'Metric: Per week savings' }
      ]
    }
  },
  computed: {
    plotTypeApplied () {
      if (this.plotType) {
        return this.plotType
      } else {
        return 'scatter'
      }
    },
    plotlyLayout () {
      return {
        title: {
          text: this.name,
          font: {
            family: 'Oxygen Mono',
            size: 16
          },
          xref: 'paper',
          x: 0
        },
        xaxis: {
          showline: true
        },
        yaxis: {
          showline: true,
          hoverformat: '.2f',
          title: {
            text: this.$t(`${this.translationPath}.y-title`),
            font: {
              family: 'Oxygen Mono',
              size: 12
            }
          }
        },
        hovermode: 'x unified',
        legend: {
          x: 0,
          y: -0.25,
          orientation: 'h'
        }
      }
    },
    plotlyData () {
      if (this.marginalPos && this.marginalNeg && this.triggerMargin && this.metric && this.data && this.data.length && this.data.length > 0) {
        const fieldSelection = Object.keys(this.data[0]).filter(f => {
          const splitted = f.split('_')
          return (
            (splitted[1] === 'POS' && splitted[2] === this.marginalPos.value) ||
            (splitted[1] === 'NEG' && splitted[2] === this.marginalNeg.value)
          ) && splitted[3] === this.triggerMargin.value && (
            (!splitted[4] && this.metric.value === 'week') || splitted[4] === this.metric.value
          )
        })
        return fieldSelection
          .map(key => {
            const curve = { x: [], y: [] }
            this.data.forEach(dataPoint => {
              if (dataPoint[key] !== null) {
                curve.x.push(new Date(dataPoint.datetime))
                curve.y.push(dataPoint[key])
              }
            })
            const typeNameIntermediate = key.includes('saveF') ? 'Forecast' : 'Max. th.'
            const typeName = key.includes('baseline') ? 'Baseline' : typeNameIntermediate
            const directionName = key.includes('POS') ? 'POS' : 'NEG'
            return {
              type: this.plotTypeApplied,
              mode: 'line',
              name: `${directionName} ${typeName} savings`,
              x: curve.x,
              y: curve.y
            }
          })
      } else {
        return []
      }
    }
  }
}
</script>
